.confirmation-modal {
}

.archive {
  .ant-modal-content {
    padding: 52px;
    border: 0;

    h2 {
      line-height: 36px !important;
      letter-spacing: -2px;
    }
    .ant-btn {
      width: 100%;
      height: 50px;

      &:hover {
        background: none;
      }
      svg {
        vertical-align: baseline;
        margin-right: 3px;
      }
    }
  }
}

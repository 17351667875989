.ant-col {
  // padding-right : 20px;
  .ant-form-item {
    margin-bottom: unset;
  }
}
.ant-select {
  // width: 150px;
}
.ant-select-dropdown {
  ::-webkit-scrollbar {
    display: unset;
    background-color: rgba(180, 181, 204, 0.2);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: rgba(51, 155, 255, 0.3);
  }
}
.single-select {
  padding-top: 0 !important;
  .ant-select-selection-search-input {
    // padding-top: 25px !important;
    height: 40px !important;
  }
}
.ant-input-prefix {
  z-index: 0;
}
.ant-form-item-control-input-content {
  .antd-primary-input {
    color: black !important;
    background: white !important;
    border: none !important;
    border-bottom: 2px solid #9090b7 !important;
    border-radius: 1px !important;
    padding: 0 !important;
    font-size: 27px !important;
    box-shadow: none;
    &:focus {
      border-bottom: 2px solid #9090b7 !important;
    }
    &::placeholder,
    .ant-select-selection-placeholder {
      color: #a5a5a5 !important;
      font-size: 27px;
    }
  }
  textarea.ant-input {
    min-height: 104px !important;
  }
}

.ant-input,
.ant-select-selector {
  min-height: 42px;
  font-size: 15px;
  background: #f8f8f8 !important;
  color: var(--primary-color) !important;
  border: 1px solid #eee3d6 !important;
  outline: none !important;
  &::placeholder,
  .ant-select-selection-placeholder {
    color: #a5a5a5 !important;
    font-size: 18px;
  }
  &:focus {
    border: 2px solid red;
    outline: none !important;
  }
}

.input-white-box {
  padding: 11px !important;
  padding-top: 13px !important;
  margin-bottom: 10px !important;
  background: #fff !important;
}
.ant-input,
.ant-select-selector {
  padding-top: 14px !important;
  padding-bottom: 0 !important;
  box-shadow: none !important;
}
.ant-select-selection-item {
  padding-top: 12px !important;
}
.ant-input:focus {
  box-shadow: unset !important;
}
.ant-btn-primary {
  border-radius: 40px !important;
  background: var(--primary-color);
  border: unset;
  min-height: 44px;
  box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.05);
  padding: 0 22px;

  span {
    font-size: 18px;
  }
  &.submit-btn {
    min-width: 200px;
    margin-top: 12px;
  }
  &.list-btn {
    min-width: 160px;
    height: 45px;
    font-weight: bold;
    span {
      font-size: 14px;
    }
  }
  &.default {
    background: #fff;
    color: var(--dark-color);
    border: solid 1px rgba(135, 149, 162, 0.4);
  }
  &.inactive {
    background: rgba(0, 119, 234, 0.2);
    border-color: rgba(0, 119, 234, 0.2);
  }
  &:hover,
  &:focus,
  &:active {
    color: #fff !important;
    background: var(--primary-color) !important;
  }
  &[disabled],
  &[disabled]:hover {
    background: grey !important;
    color: white !important;
  }
}
.ant-btn-secondary {
  border-radius: 40px !important;
  background: #f3f3f3;
  border: 0 !important;
  min-height: 44px;
  color: var(--dark-color);
  font-weight: 500;
  padding: 0 22px;
  span {
    font-size: 17px;
  }
  &:hover,
  &:focus,
  &:active {
    background: #f3f3f3 !important;
    min-height: 44px;
    color: var(--dark-color);
  }
}
.ant-cancel-btn {
  border-radius: 30px !important;
  background-color: var(--light-grey) !important;
  min-height: 43px;
  color: var(--dark-color);
  font-weight: 600;
  border: 0;
  span {
    font-size: 17px;
  }
  &:hover,
  &:focus,
  &:active {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }
}

.ant-dropdown-button {
  .ant-btn-default {
    border: 0 !important;
    border-color: transparent !important;

    &:hover {
      border: 0 !important;

      svg {
        path {
          fill: #fce57e;
        }
      }
    }

    &:active {
      border: 0 !important;
      border-color: transparent !important;
    }
  }
  .ant-dropdown-trigger,
  .ant-btn-icon-only .ant-btn {
    border: 0;

    &:hover {
      border: 0;
    }

    &:active {
      border: 0;
    }
  }
}
.ant-dropdown-menu,
.ant-menu-vertical {
  min-width: 165px;
  border: 1px solid #f3ddc2;
  padding: 0;
  .ant-menu-item {
    display: flex;
    align-items: center;
  }
  > li {
    border-bottom: 1px solid #f3ddc2;
    margin: 0 !important;

    > span {
      margin-left: 5px;
    }

    > svg {
      width: 13px !important;
    }
  }
  .ant-menu-item-selected {
    background-color: var(--medium-cream-color) !important;
  }
}

.ant-dropdown-menu-submenu {
  border: 0px;
}

.ant-checkbox-group-item {
  margin-right: 45px !important;
  .ant-checkbox + span {
    font-size: 16px;
    font-weight: 500;
    color: #0d1319;
  }
}
.ant-form-item {
  margin-bottom: 0px !important;
}
.ant-form-item .ant-upload.ant-upload-drag {
  min-height: 240px;
  min-width: 230px;
  background: none;
  border: none;
  .ant-upload-drag-container {
    max-width: 320px;
  }
}
.ant-upload {
  width: 100% !important;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: flex !important;
  justify-content: center !important;
  .ant-btn-primary {
    height: 40px !important;
  }
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 5px 0 10px !important;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 1.36;
  color: #0d1319 !important;
}
.single-group {
  margin: 25px 0 10px !important;
}
.no-margin-bottom {
  margin-bottom: unset !important;
}
.ant-collapse {
  border: unset !important;
  .ant-collapse-item {
    background: #fff;
    .ant-collapse-header {
      padding: 22px 16px;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
.ant-modal-wrap {
  @include mobile {
    backdrop-filter: none !important;
  }
}
.ant-modal-mask {
  @include mobile {
    background-color: #000000cc;
    backdrop-filter: none;
  }
}
.ant-modal-body {
  padding: 20px 16px !important;
}
.ant-collapse-content {
  border-top: unset !important;
  padding-bottom: 16px;
}
.ant-modal-content {
  border-radius: 5px !important;
}
.add-modal {
  max-height: 320px !important;
  top: 30% !important;
  .ant-modal-content {
    max-width: 80%;
    margin: 0 auto;
  }
}
.add-category-modal {
  .ant-modal-body {
    position: relative;
    padding: 0 40px 33px !important;
    min-height: 80vh;
  }
  .ant-modal-close {
    top: 34px;
    right: 40px;
  }
}
.add-category-modal,
.category-modal,
.confirmation-modal {
  .ant-modal-close-x {
    width: 31px;
    height: 31px;
    line-height: 31px;
  }
}
.category-modal {
  &.ant-modal {
    padding: 0;
  }
  .ant-modal-body {
    padding: 0 !important;
    height: calc(80vh - 103px);
  }
  .ant-modal-footer {
    padding: 26px 0;
    justify-content: center;
    display: flex;
    .add-category {
      padding: 0 65px;
      letter-spacing: -0.32px;
    }
  }
  .ant-modal-close {
    top: 45px;
    right: 40px;
  }
}
.confirmation-modal {
  .ant-modal-body {
    padding: 58px 60px 38px !important;
  }
  .ant-modal-close {
    top: 10px;
    right: 15px;
  }
}
// overwriting css for full screen modal
.full-view-modal {
  top: unset !important;
  width: 100% !important;
  .ant-modal-content {
    width: 100% !important;
    max-width: unset !important;
  }
  .ant-modal-close-icon,
  .ant-modal-close {
    display: none !important;
  }
  .ant-modal-body {
    padding: unset !important;
  }
}
// overwriting css for modal
.inlineRadioFields {
  display: flex;
  align-items: center;
  .radio-buttons {
    margin-bottom: 10px !important;
  }
}
.radioButtons {
  margin-bottom: 20px;
  span {
    font-size: 16px;
    color: var(--dark-color);
  }
  &.largeMargin {
    margin-right: 55px;
  }
}
.radio-buttons {
  margin-bottom: 35px !important;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px !important;
}
.ant-checkbox-group {
  &.heading-present {
    margin: 0 0 10px !important;
  }
}
.ant-input-number {
  width: 52px !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.select-enter {
  &.doNotShow {
    display: none;
  }
  input {
    border-right: 0px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .ant-select-selector {
    border-top-left-radius: 0 !important;
    background-color: #fff !important;
    padding: 11px 16px !important;
    border-bottom-left-radius: 0 !important;
  }
}
.filters-header {
  padding: 15px 30px;
  padding-right: 15px;
  border-bottom: 1px solid #d9d9d9;
  .filters-heading {
    color: #6c7a8f;
  }
  .clear-text {
    color: rgba(135, 149, 162, 0.4);
    &.filters-present {
      color: #339bff;
    }
  }
}
.filters-form-collapse {
  .reset-field {
    color: #339bff;
    margin-right: 10px;
  }
  .ant-collapse {
    .ant-collapse-header {
      padding: 15px 30px !important;
      color: #6c7a8f !important;
      font-size: 14px;
    }
    .ant-collapse-content-box {
      padding: 0 30px !important;
    }
  }
  .ant-checkbox-group {
    margin: unset !important;
  }
  .ant-checkbox-group-item {
    margin: 10px 0;
    margin-right: 20px !important;
  }
  .ant-checkbox-group-item .ant-checkbox + span {
    color: #6c7a8f !important;
    font-size: 14px !important;
  }
}
.ant-btn-sm {
  min-height: 30px !important;
}
.time-picker {
  .ant-btn-sm {
    min-height: 30px !important;
  }
}
.ant-input-padding {
  padding: 11px !important;
}
span.ant-radio + * {
  color: #0d1319;
  text-transform: capitalize;
}
.policies-modal {
  .ant-modal-title {
    font-size: 18px;
    font-weight: bold;
    color: #0d1319;
  }
  .ant-modal-header {
    padding: 25px;
  }
  .ant-modal-body {
    padding: 10px 24px !important;
    height: 560px;
    overflow-y: auto;
  }
  .ant-modal-close {
    top: 5px !important;
  }
  .ant-divider-dashed {
    border-color: var(--primary-color);
    margin: 15px 0 20px;
  }
}
.data-table {
  margin-bottom: 20px !important;
  .ant-input {
    padding: 11px !important;
  }
}
.text-area-container {
  .ant-form-item-control-input-content {
    border-radius: 5px !important;
    border: solid 1px rgba(135, 149, 162, 0.4) !important;
    padding-top: 10px;
    background-color: rgba(135, 149, 162, 0.06) !important;
  }
  .ant-input-textarea {
    textarea {
      border: unset !important;
      background: unset !important;
      padding-bottom: 10px !important;
    }
  }
}
.ant-input-textarea {
  textarea {
    min-height: 108px;
  }
}
.ant-form-item-explain {
  font-size: 11px !important;
  color: var(--error-color) !important;
  margin-top: 2px;
  min-height: unset !important;
  position: absolute;
  bottom: -20px;
}
.ant-input,
.ant-select-arrow,
.ant-select-selection-item {
  color: var(--primary-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // margin-top: 0;
  // margin-bottom: 0.5em;
  color: var(--dark-color);
  // font-weight: 500;
}

.assessmentComposition {
    padding: 15px 20px 20px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 25px;
    flex: 1 1 0%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.headerText {
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
}

.subTitle {
    font-size: 14px;
    opacity: 0.8;
    line-height: 24px;
}

.timeContainer {
    display: flex;
    align-items: center;
    background-color: #daeeee;
    border-radius: 40px;
    padding: 11px 20px;

    .clockIcon {
        margin-right: 4px;
    }

    .timeText {
        font-size: 14px;
        font-weight: 500;
        color: #184444;
        text-transform: uppercase;
        white-space: nowrap;
    }
}

.skillsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 12px;
    max-width: 70vw;
}

.modulesContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;

    @media (min-width: 1280px) {
        grid-template-columns: 1fr 1fr;
    }
}

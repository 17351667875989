.tag {
  height: 37px !important;
  margin: 13px 32px 10px 10px !important;
  padding: 10px 11px !important;
  border-radius: 5px !important;
  letter-spacing: -0.43px !important;
  color: #0d1319 !important;
  display: inline !important;
}

.tag {
  &-blue {
    @extend .tag;
    background-color: rgba(0, 119, 234, 0.1) !important;
  }
}

.product-tag {
  padding: 5px 10px;
  border-radius: 4px;
  background-color: #f0f3fa;
}

.product-tag {
  &-blue {
    @extend .product-tag;
    background-color: #f0f3fa;
  }
}

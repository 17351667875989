@import "/src/scss/mixins";
@import "/src/scss/variables";

/* All sub containers are positioned relative to .position-stats-card.*/
.position-stats-card {
  width: 100%;
  height: 300px;
  padding: 6px 16px;
  background: var(--text-white);
  color: #000000 !important;
  border: 1px solid #f3ddc2;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);

  @include mobile {
    padding: 10px 16px;
  }

  &.opacity-5 {
    @include mobile {
      opacity: 1;
    }
  }

  &:hover {
    box-shadow: -4px 5px 5px 0px rgba(133, 75, 5, 0.12);
    transform: translateY(-10px);
    @include mobile {
      transform: none;
    }
  }

  .background-overlay {
    display: none;
    @include mobile {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000000cc;
      z-index: 1;
      &.hide {
        display: none;
      }
    }
  }

  .position-icon-box {
    top: -20px;
    width: 73px;
    height: 73px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--light-dark-color);
    border: 4px solid var(--mid-dark-color);
  }

  .expertise-tag {
    width: fit-content;
    border: solid thin var(--cream-white);
    border-radius: 20px;
    font-weight: 600;
  }

  .cancel-tag {
    border: 1px solid var(--primary-color);
    background: var(--primary-color);
    color: var(--text-white);
    border-radius: 20px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 16px;
    z-index: 50;
  }

  .created {
    @include mobile {
      margin-top: 30px !important;
    }
  }

  .stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    @include mobile {
      margin-top: 10px;
    }

    .stats-with-icon {
      @include mobile {
        width: 100%;
        padding: 0;
      }

      h1 {
        @include mobile {
          font-size: 22px;
          line-height: 26px;
          letter-spacing: -0.5px;
        }
      }

      p {
        @include mobile {
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          white-space: nowrap;
        }
      }
    }
  }

  .edit-name {
    width: 65% !important;
    border: none !important;
    border-bottom: 1px solid #eee3d6 !important;
  }

  .save-edit-options {
    display: flex;
    justify-content: center;
    align-items: center;

    .check-icon {
      cursor: pointer;
      margin-left: 10px;
      background: #F3F3FA;
      border-radius: 100%;
      padding: 6px;
    }

    .close-button {
      cursor: pointer;
      circle {
        transition: fill 0.24s linear;
      }

      &:hover {
        circle {
          transition: fill 0.24s linear;
          fill: #e1e1e6;
        }
      }
    }

    .check-icon {
      transition: background-color 0.24s linear;

      &:hover {
        transition: background-color 0.24s linear;
        background: #e1e1e6;
      }
    }
  }
}
.position-header-container {
  width: 93%;
  z-index: 2;
}

.position-data-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 80%;
  bottom: 8px;

  @include mobile {
    height:  unset;
    display: unset;
  }
}

.active-card {
  transform: translateY(-10px) !important;
  @include mobile {
    transform: none !important;
    // &:before {
    //   content: "";
    //   display: block;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   position: fixed;
    //   background: #000c;
    //   z-index: -1;
    // }
  }
}

.optionmenu {
  @include mobile {
    transition: 0.2s transform ease;
    transform: translateY(100%);

    &.open {
      transform: translateY(0%);
      box-shadow: 0px -4px 10px 5px #0001;
    }
  }

  .ant-dropdown-menu > li {
    span {
      &:first-child {
        margin: 0px;
      }
    }
  }
}

.attention-trait {
  gap: 10px;
  align-items: flex-start;
  .ring {
    padding: 3px;
    border: 1px solid #d2d2d2;
    border-radius: 50%;
    transform: rotate(45deg);
  }
  .score {
    color: white;
    font-weight: 600;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-45deg);
  }
}

@import "src/scss/mixins";

.snapshots-wrap {
  backdrop-filter: none !important;
}

.snapshots-modal {
  min-width: 600px;

  .ant-modal-content {
    border-style: none !important;
    border-radius: 20px !important;

    .filter-btn {
      padding: 8px 20px;
      background: #e8e8e8;
      border-style: none;
    }

    .selected-filter-btn {
      background: #1c1c1c;
    }

    .grid {
      height: 400px;
      padding: 0px 8px;
      padding-top: 8px;
      overflow-y: auto;

      .height-fit {
        height: fit-content;
      }

      .critical-icon {
        border-radius: 50%;
        padding: 5px 10px;
        top: -8px;
        right: -8px;

        &-err {
          background: #d24444;
        }

        &-warn {
          background: #FFDB8F;
        }
      }

      .snap-width {
        width: 190px;
        height: 210px;
        border-radius: 10px;

        @include mobile {
          width: 140px;
          height: 144px;
        }
      }

      .snap-overlay {
        background: linear-gradient(rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.6) 100%);
      }

      &.mobile-grid-cols {
        @include mobile {
          grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
        }
      }
    }
  }
}

@for $i from 1 through 4 {
  .snap-critical-width-#{$i} {
    width: $i * 204px;
    height: 400px;

    @include mobile {
      width: auto;
    }
  }
}
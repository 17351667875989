@import "src/scss/variables";
@import "src/scss/mixins";

#one-page-report-component {
  display: flex;
  height: 100vh;

  @include mobile {
    display: flex;
    flex-direction: column;
    height: fit-content;
  }

  .max-width-left {
    max-width: calc(1440px * (65 / 100));
    margin-left: auto;

    @at-root .v2#{&} {
      max-width: 1150px;
      margin: auto;
      @include desktop-lg {
        padding: 30px 50px;
      }
    }
  }

  .max-width-right {
    max-width: calc(1440px * (35 / 100));
    margin-right: auto;
  }

  h4 {
    color: inherit;
  }

  .default-btn {
    background: #f2f2fb;
    color: #5c5c7c;
    border: none;
    // padding: 10px 15px;
    border-radius: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.03em;
    padding: 14px 18px;

    @include mobile {
      font-size: 13px;
      padding: 10px;
    }
  }

  .copy-url {
    margin-right: 10px;
    padding: 8px;
    color: #555;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.1s;
    border: 1px solid #ddd;
    &:hover {
      background: #f5f5f5;
      color: #000;
    }
  }

  .info {
    flex: 0.65;

    &.v2 {
      flex: 1;

      & > .content-wrapper,
      .header {
        @include desktop {
          padding: 30px 50px;
        }

        @include desktop-lg {
          padding: 0;
        }
      }

      .trait-fill-block-component {
        height: 26px;
      }
    }

    .header {
      color: #000;
      border-bottom: 1px solid #babacd;
      padding: 23.5px 28px;

      @include mobile {
        padding: 24px 20px;
        & > div {
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
        }
      }

      &--goBack {
        display: flex;
        flex-direction: column;
        gap: 6px;
        cursor: pointer;
        width: fit-content;
        .icon {
          width: 15px;
          transform: rotate(180deg);
          @include mobile {
            width: 10px;
          }
        }
        .text {
          color: #160b45;
          font-weight: 600;
          font-size: 18px;
          // line-height: 33px;
          letter-spacing: -1px;
          @include mobile {
            font-size: 14px;
          }
        }
      }

      .candidate-bio {
        .name {
          font-size: 18px;
          font-weight: bold;
          line-height: 20px;
          letter-spacing: -0.04em;
          @include mobile {
            font-size: 16px;
          }
        }

        .email {
          font-size: 16px;
          font-weight: 600;
          line-height: 10px;
          letter-spacing: -0.04em;
          @include mobile {
            font-size: 14px;
            margin-bottom: 4px;
          }
        }

        .status-pill {
          position: relative;
          top: 2px;
          padding: 6px 10px;
          border-radius: 50px;
          display: flex;
          width: min-content;
          min-width: 100px;
        }

        .meta {
          font-weight: 600;
          font-size: 14px;
          line-height: 14px;
          letter-spacing: -0.03em;
          color: #6d6d85;
          margin-top: 4px;
          @include mobile {
            font-size: 12px;
          }
        }
      }

      .divider {
        background-color: #babacd;
        width: 1px;
        margin: 0 14px;
        height: 37px;
      }

      .view-long-report {
        display: flex;
        align-items: center;
        gap: 9px;
        background-color: #000;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        border-radius: 40px;
        padding: 14px 18px;
        border: none;
        line-height: 19px;
        text-align: right;

        @include mobile {
          font-size: 13px;
          padding: 10px 15px;
          gap: 5px;
          text-align: center;
          & > img {
            width: 12px;
          }
        }
      }
    }

    .content-wrapper {
      padding: 28px;
      height: calc(100% - 95px);
      overflow: scroll;
      @include mobile {
        padding: 28px 20px;
      }
    }

    .fit-score {
      padding: 0 0 16px;

      .band {
        width: 100%;
        height: 106px;
        padding: 12px;
        border-radius: 2px;
        position: relative;
        background-color: #f2f2fb;
        border-radius: 2px;

        .fill {
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          border-radius: 0 2px 2px 0;
          border-right: 1px solid #fff;
          width: 0;
          transition: 1s all ease-in-out;
        }

        .content {
          position: absolute;
          top: 12px;
          left: 12px;
          .fit-label {
            font-size: 18px;
            font-weight: 600;
            line-height: 19px;
            letter-spacing: -1px;
            @include mobile {
              font-size: 16px;
            }
          }

          .score {
            font-size: 78px;
            font-weight: 700;
            line-height: 60px;
            letter-spacing: -2px;
            margin-top: 4px;
            z-index: 1;
            @include mobile {
              font-size: 50px;
            }
          }
        }

        > .median {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          border-left: 2px dashed #160b45;
          z-index: 0;
          transform: translateX(-50%);
          // transition: 0.2s all linear;
          > .icon-label {
            position: absolute;
            bottom: 8px;
            left: -6px;
            transform-origin: bottom left;
            transform: rotate(-90deg);
            font-weight: 600;
            font-size: 13px;
            line-height: 15px;
            letter-spacing: -0.03em;
            @include mobile {
              font-size: 12px;
            }
          }
        }
        > .median-icon {
          position: absolute;
          transform: translate(-50%, -50%);
          top: 0;
          left: 0;
          // transition: 0.2s all linear;
          @include mobile {
            width: 26px;
          }
        }
      }
      .scales {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 28px;
        padding-top: 16px;
        @include mobile {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;
        }

        .scale {
          display: flex;
          align-items: center;
          width: fit-content;
          gap: 6px;

          .color-circle {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          span {
            color: #9696b2;
            font-size: 13px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: -0.03em;
            @include mobile {
              flex: 1;
            }

            &.active {
              color: #160b45;
            }
          }
        }
      }
    }

    .traits-section-mba {
      margin-bottom: 8px;

      .description-box {
        position: absolute;
        top: 0;
        width: 30ch !important;
        @include mobile {
          width: 100% !important;
        }
        @include rwd-cr(768, 900) {
          width: 25% !important;
        }
      }

      .traits-grid {
        grid-template-columns: repeat(3, 173px) !important;
      }
    }

    .traits-section {
      display: flex;
      justify-content: space-between;
      padding: 16px 0 16px;
      border-top: 1px solid #babacd;
      height: fit-content;
      position: relative;

      @at-root .v2#{&} {
        gap: 10%;
        padding: 30px 0;
      }

      @include mobile {
        flex-direction: column;
        gap: 20px;
      }

      .description-box {
        width: 100%;
        margin-top: 20px;
        padding: 10px 14px;
        background: #f2f2fb;
        display: flex;
        transition: 0.2s all linear;
        gap: 7px;

        .drawer-header {
          display: hidden;
        }

        @include mobile {
          position: fixed;
          bottom: 0;
          left: 0;
          z-index: 1;
          margin: 0;
          border-top: 1px solid rgba(255, 255, 255, 0.15);
          padding: 0px;
          // opacity: 1 !important;
          flex-direction: column;
          gap: 0;
          width: 100%;
          height: fit-content;

          backdrop-filter: blur(10px);
          background: #00000090;
          color: var(--text-white);
          // background: var(--primary-color);

          .drawer-header {
            text-align: center;
            padding: 10px;
            border-bottom: 1px solid #ffffff26;
            transition: 0.1s all linear;
          }

          &.opacity-0 {
            .drawer-header {
              height: 0;
              padding: 0;
            }
            > p {
              height: 0px;
              padding: 0 15px;
            }
          }
        }

        .icon {
          width: 22px;
          @include mobile {
            display: none;
          }
        }

        p {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.02em;
          color: #2a2a41;
          transition: 0.2s all linear;

          @include mobile {
            color: inherit;
            letter-spacing: normal;
            padding: 10px 15px 20px;
            // font-size: 16px;
          }
        }
      }

      .ai-analysis-box {
        width: 100%;
        margin-top: 20px;
        padding: 10px 14px;
        background: #f2f2fb;
        display: flex;
        transition: 0.2s all linear;
        gap: 7px;

        .ai-analysis-logo {
          border-radius: 5px;
          background: #FFF;
          padding: 7px 10px 7px 8px;
          span {
            color: #E97F40;
            font-size: 12px;
            font-weight: 700;
            line-height: 15px;
            text-transform: uppercase;
            letter-spacing: -0.28px;
          }
        }
        .ai-analysis-score {
          line-height: 20px;
          letter-spacing: -0.02em;
          font-size: 16px;
          text-align: left;
          .score {
            font-weight: 700;
          }
          .trait-name {
            font-weight: 600;
          }
        }
        .trait-desc {
          font-size: 14px;
          font-weight: 400;
        }
      }

      .info {
        flex: 0.3;

        @at-root .v2#{&} {
          flex: 1;

          .summary {
            margin-top: 9px;
          }

          @include mobile{
            margin-bottom: 16px;
          }
        }

        &.dim {
          .title,
          .summary {
            opacity: 0.1;
          }
        }

        .title {
          font-size: 16px;
          font-weight: 900;
          color: #2a2a41;
          line-height: 19px;
          text-transform: uppercase;
        }

        .summary {
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: -0.03em;
          color: #6d6d85;
          margin-top: 5px;
        }
        .summary-mt {
          margin-top: 12px;
        }
      }

      .traits-grid {
        flex: 0.6;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 6px;
        height: 100%;
        @include mobile {
          height: fit-content;
          display: flex;
          flex-direction: column;
        }

        @at-root .v2#{&} {
          position: relative;
          @include rwd-cr(1400, 2560) {
            left: 105px;
          }
          @include rwd-cr(1200, 1440) {
            left: 150px;
          }
          @include rwd-cr(1150, 1200) {
            left: 120px;
          }
          @include rwd-cr(1100, 1150) {
            left: 90px;
          }
          @include rwd-cr(998, 1100) {
            left: 60px;
          }
          flex: 2.5 1;
          @include mobile {
            display: grid !important;
            justify-content: center;
            grid-template-columns: repeat(2, 1fr) !important;
            left: unset;
          }
        }
      }

      .empty-state-wrapper {
        flex: 0.6;
        > div {
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &.v2 {
        flex-direction: column;
        padding: 0;
        border: none;
        gap: 25px;

        &.singleRow {
          flex-direction: row;
          align-items: center;
          
          &.description {
            @include mobile {
              flex-direction: column;
              align-items: flex-start;
            }
          }
        }
      }
    }
  }

  .unberry-insights {
    flex: 0.35;
    height: 100%;
    overflow: auto;
    background: var(--dark-color);

    .header {
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      padding: 30px;
      @include mobile {
        padding: 20px;
      }

      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: var(--text-white);
      }
    }

    .content-wrapper {
      padding: 0 28px;
      height: calc(100% - 95px);
      overflow: auto;
      @include mobile {
        padding: 10px 20px;
      }
    }

    .traits-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;
      > * {
        flex: unset;
      }
    }

    .collapse-arrow {
      &.rotate {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    .collapse-heading {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: var(--text-white);
      opacity: 0.8;
      @include mobile {
        font-size: 18px;
      }

      &.disabled {
        opacity: 0.4;
      }
    }

    .collapse-content {
      .heading-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > * {
          flex: unset;
        }

        .probe-btn {
          outline: none;
          background: transparent;
          color: #fff;
          border-radius: 41px;
          border: 1px solid #454547;
          padding: 7px 15px;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 5px;
        }
      }
      .statement {
        margin: 6px 0 24px !important;
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: -0.02em;
        color: #c7c7c7;
        @include mobile {
          font-size: 14px;
        }
      }
      & > .dim {
        opacity: 0.2;
      }
      & > div:last-of-type {
        .statement {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  .trait-box {
    flex: 1;
    display: flex;
    align-items: flex-end;
    padding: 12px;
    border-radius: 2px;
    position: relative;
    min-height: 70px;
    transition: 0.2s all linear;
    cursor: pointer;

    &::after {
      content: "";
      // background-color: #fff;
      border: 6px solid var(--bg-white);
      border-left-color: transparent;
      border-bottom-color: transparent;
      position: absolute;
      top: 0;
      right: 0;
    }

    &.dim {
      opacity: 0.2;
    }

    &.dark {
      &::after {
        border-top-color: var(--dark-color);
        border-right-color: var(--dark-color);
      }
      &.dim {
        opacity: 0.5;
      }
    }

    &.small {
      background: #454547;
      color: var(--text-white);
      width: fit-content;
      min-height: fit-content;
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: -1px;
      white-space: nowrap;
      padding: 5px 7px 5px 5px;
      @include mobile {
        font-size: 14px;
      }
    }

    &.no-tag {
      padding: 0;
      background: transparent;
      letter-spacing: normal;
    }

    &.active {
      background: #f2f2fb !important;
    }

    > .magic-wand-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 2;
    }

    > .fill {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      border-radius: 0 2px 2px 0;
      border-right: 1px solid #fff;
      transition: 0.2s all ease-in-out;

      &.not-active {
        width: 0 !important;
      }
    }

    > h4 {
      white-space: nowrap;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.5px;
      position: relative;
      z-index: 0;
      @include mobile {
        white-space: normal;
        font-size: 14px;
      }

      @at-root .v2#{&} {
        white-space: unset;
        margin-top: 30px;
      }
    }

    > .score {
      position: absolute;
      top: 12px;
      left: 12px;
      font-weight: bold;
      font-size: 20px;
      transition: 0.2s all linear;
      @include mobile {
        font-size: 18px;
      }
    }

    > .median {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-left: 2px dashed #160b45;
      z-index: 0;
      transition: 0.2s all linear;
      transform: translateX(-50%);
      @include mobile {
        // border-width: 1px;
      }
    }
    .median-icon {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 0;
      left: 0;
      @include mobile {
        width: 26px;
      }
    }

    &.v2 {
      padding: 10px 13px;
      flex: initial;
      color: var(--black-100);
      background-color: var(--tuft-bush-tint);
      cursor: auto;
      line-height: 19px;
      letter-spacing: -0.5px;
    }
  }

  // Below this line is the code for v2
  .traits-list {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
    margin-top: 20px;
  }

  .trait-score {
    display: flex;
    flex: 1;
    align-items: center;
    border: 0;
    padding: 0;

    .score {
      font-weight: 700;
      min-width: 90px;

      @include mobile {
        min-width: 70px;
      }
    }

    @include mobile {
      width: 100%;
    }
  }

  .group-container {
    margin-top: 35px;
    
    /* Only disabled for UAT temporarily */
    &:first-child {
      margin-top: unset;
      section {
        border-top: unset;
      }
    }

    @include mobile {
      &:last-of-type {
        padding-bottom: 30px;
      }
    }

    &--traits {
      padding: 35px 0;
      display: grid;
      column-gap: 10%;
      grid-template-columns: 1fr 1fr;

      & > .border-line {
        grid-column: 1 / -1;
        height: 1px;
        background-image: linear-gradient(
          to right,
          #babacd 50%,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: bottom;
        background-size: 20px 1px;
        background-repeat: repeat-x;
        margin: 30px 0;

        @include mobile {
          display: none;
        }
      }

      @include mobile {
        grid-template-columns: 1fr;
        row-gap: 30px;
      }
    }
  }

  .group-name {
    font-weight: 700;
    font-size: 26px;
    padding: 0 0 24px;
    border-bottom: 1px solid #babacd;
    color: #2a2a4180;
  }

  .bucket-detail-container {
    & > .traits-section.v2 {
      margin-top: 35px;
    }

    .group-container {
      border-top: 1px solid #babacd;
      padding-top: 30px;

      h4 {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #2a2a41;
      }

      &--traits {
        column-gap: 80px;
        row-gap: 40px;

        .traits-section.v2 {
          .score {
            font-size: 25px;
          }

          & > div:last-of-type {
            flex: 1.5;
          }
        }
      }
    }
  }

  .single-bucket {
    border-top: 1px solid #babacd;
    margin-top: 20px;
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    gap: 10%;
    /* Only disabled for UAT temporarily */
    &:first-child {
      border-top: unset;
      margin-top: unset;
    }

    @include mobile {
      display: unset;
    }

    &--name {
      font-size: 16px;
      font-weight: 900;
      color: #2a2a41;
      line-height: 19px;
      text-transform: uppercase;

      @include mobile{
        margin-top: 20px;
      }
    }

    &--description {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: -0.03em;
      color: #6d6d85;
      margin-top: 9px;

      @include mobile{
        margin-bottom: 9px !important;
      }
    }

    & > div:first-child {
      flex: 1;
    }

    & > div:last-child {
      flex: 2.5;
    }

    & .overall-score {
      padding: 23px 30px;
      border: 1px solid #e4e4e4;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include rwd(478) {
        display: inherit;
      }

      &:not(:first-of-type) {
        margin-top: 15px;
      }
    }

    .bucket-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include rwd-cr(998, 2560) {
        width: 500px;
      }
      gap: 50px;

      @include mobile {
        justify-content: unset;
      }

      &--score {
        // width: 90px;
        @include mobile {
          width: unset;
        }
        font-weight: 700;
        font-size: 45px;
        line-height: 54px;
        letter-spacing: -0.5px;
      }

      &--name {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.5px;
      }

      // Todo: not being used as of now.
      &--description {
        margin-top: 5px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

      }

      &--view-btn-container {
        @include mobile {
          display: flex;
          justify-content: flex-end;
        }
      }

      &--view-btn {
        background: #1c1c1c;
        border-radius: 40px;
        padding: 13px 27px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        border: none;
        white-space: nowrap;

        @include rwd(478) {
          margin-top: 12px;
        }
      }

      .traits-list {
        margin-top: 5px;
        @include rwd-cr(998, 2560) {
          width: 386px;
        }
      }
    }
  }
}

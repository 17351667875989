@import "src/scss/mixins";

.unberry-insights {
  .dark-theme {
    border-radius: 0 !important;
    background-color: var(--dark-color) !important;
    .ant-collapse-item.ant-collapse-no-arrow {
      .ant-collapse-header {
        padding: 24px 0;

      }
    }
    .ant-collapse-header {
      background-color: var(--dark-color) !important;
      color: var(--text-white) !important;
      padding: 24px 0;
      @include mobile {
        padding: 16px 0;
      }
    }
    .ant-collapse-content {
      background-color: var(--dark-color) !important;
      color: var(--text-white) !important;
      padding-bottom: 24px;
      border-radius: 0 !important;
      .ant-collapse-content-box {
        padding: 0 !important;
      }
    }
    .ant-collapse-item {
      background-color: var(--dark-color) !important;
      border-bottom: 1px solid #ffffff26;
    }
  }
}

@import "src/scss/mixins";

.country-add-on-container {
  position: relative;
  z-index: 1;
  cursor: pointer;
  .ant-select-selection-search {
    cursor: pointer;
  }

  .country-code-select {
    opacity: 0;
    position: absolute; 
    left: 0; 
    top: 0;
  }
}

.phone-field {
  .ant-input {
    padding-top: 0px !important;
    &::placeholder,
    &::-webkit-input-placeholder  {
      font-size: 16px;
    }
  }
}

.flag-search {
  .flag-search-input {
    padding-left: 24px;
    padding-bottom: 12px;
    margin: 8px 20px;
    width: 100%;
    background: inherit;
    border: unset;
    border-image: unset;
    border-bottom: 2px solid #dfdfdf;
    color: #bbbbbb;
    font-size: 18px;
    &:focus-visible {
      outline: unset;
    }
    &::placeholder,
    &::-webkit-input-placeholder  {
      position: relative;
      top: 4px;
      color: #bbbbbb;
      font-size: 16px;
      letter-spacing: 0.3px;
    }
    caret-color: #000;
  }
  .flag-search-icon {
    position: absolute;
    top: 21px;
    left: 12px;
    font-size: 24px;
    opacity: 0.9;
    color: #000;
  }
}

.country-code-dropdown {
  border-radius: 16px !important;
  .ant-select-item-option-content {
    display: flex;
    align-items: center;
  }
  max-width: 300px !important;
  span {
    margin-left: 12px;

    &:first-of-type {
      font-weight: 500;
      margin-left: 12px;
    }
    &:last-of-type {
      opacity: 0.9;
      font-weight: 500;
      font-size: 14px;
      margin-left: 12px;
    }
  }

  @include mobile {
    transform-origin: top left !important;
    left: 0;
    top: 100%;
  }
}

.flag-phone-ext {
  width: 100%;
  position: relative;
  z-index: -1;
  .flag-container {
    position: relative;
    top: 3px;
  }
  span {
    width: 100%;
    font-size: 14px;
    position: relative;
    right: 6px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


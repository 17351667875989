@import "src/scss/mixins";
@import "src/scss/variables";

.round-container {
  width: 344px;
  height: 344px;
  background-color: #f7f1ea !important;
  border-radius: 200px;
  @include mobile {
    width: 250px;
    height: 250px;
    >p {
      font-size: 20px !important;
      padding: 10px 18px 0;
    }
  }
}

.invite-modal-content {
  .btn-box {
    .ant-btn {
      width: 100%;
    }
  }
  .ant-form-item-children-icon {
    top: 45% !important;
  }
  .ant-select-selector {
    padding-top: 0 !important;
  }
}

.invite-btn {
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
}
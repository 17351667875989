// to hide the bottom border of antd tabs
.tabs-no-bottom-border {
  .ant-tabs-nav::before {
    display: none !important;
  }
}
// darker version of antd tabs
.card-tabs-dark {
  .ant-tabs-tab {
    margin: 0 10px 0 0 !important;
  }
  .ant-tabs-tab-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #333333 !important;
    color: #333333 !important;
    border-radius: 13px !important;
    height: 27px !important;
    padding: 6px 20px !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333333 !important;
    color: #ffffff !important;
    border-radius: 13px !important;
    height: 27px !important;
    padding: 6px 20px !important;
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none !important;
  }
  .ant-tabs-nav::before {
    display: none !important;
  }
}

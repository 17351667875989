@import "../../../scss/mixins";
@import "../../../scss/variables";

.dna-info-card {
  width: 100%;
  background: var(--text-white);
  margin-top: 30px;

  @include rwd-cr(768, 1280) {
    display: flex;
    flex-direction: column;
    height: 300px;
    flex-wrap: wrap;
  }
  
  &.show-pubilc-links {
    @include rwd-cr(768, 1280) {
      height: 400px;
    }
  }
  // Margin only when rendered from my-dna-detail
  &.my-dna-detail {
    @include rwd-cr(1040, 1281) {
      margin-left: 140px;
    }

    @include rwd-cr(991, 1040) {
      margin-left: 80px;
    }
  }
  
  @include mobile {
    margin: 0;
  }
  .position {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    text-transform: capitalize;
    @include mobile {
      font-weight: bold;
      font-size: 28px;
      line-height: 32px;
    }
  }

  @include tablet {
    width: 100%;
    max-width: none !important;
  }

  .position-title {
    border-bottom: 4px solid #160b45;
    color: #160b45 !important;
    padding-bottom: 23px;
    flex: 1;
    width: 100%;
    word-break: keep-all;

    @include tablet {
      width: 100%;
      border: none;
    }
    @include mobile {
      padding-top: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .close-button {
      display: none;
      @include mobile {
        display: block;
      }
    }
  }

  .position-icon-box {
    top: -20px;
    width: 73px;
    height: 73px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--light-dark-color);
    border: 4px solid var(--mid-dark-color);
  }

  .cancel-tag {
    border: 1px solid var(--primary-color);
    background: var(--primary-color);
    color: var(--text-white);
    border-radius: 20px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 16px;
    z-index: 50;
  }

  .public-links-container {
    @include rwd-cr(768, 1280) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .self-sign-up {
      width: 100%;
      padding: 16px 12px;
      letter-spacing: -0.2px;
      background: #FFFBF6;
      border: 1px solid #A4A4A4;
      border-radius: 6px;
      @include rwd-cr(768, 1280) {
        width: 45%;
      }

      .header {
        width: 100%;
      }
      .desc {
        color: #8D8D8D;
      }
    }
  }

  .traits-container {
    padding-top: 30px;

    @include rwd-cr(768, 1281) {
      padding-top: 0px !important;
      order: 2;
      width: 260px;
    }

    @include rwd-cr(768, 991) {
      margin-left: 50px;
    }

    @include rwd-cr(900, 991) {
      margin-left: 130px;
    }

    @include rwd-cr(991, 1281) {
      margin-left: 200px;
    }
    
    .empty-state {
      left: 0;
      top: 50%;
      z-index: 1;
      width: 90%;
      > p {
        margin-top: 4px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.5px;
        color: #1c1c1c;
      }
    }

    > .overall-heading {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.5px;
      text-transform: capitalize;
    }

    .performance-bars {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      &.empty {
        filter: blur(13px);
      }

      .bar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 55px;
        padding: 0px 14px;
        border-radius: 1px;
        cursor: pointer;
        @include mobile {
          min-height: 55px;
          padding: 7px 12px;
        }
        .percentage {
          font-weight: 600;
          font-size: 22px;
          line-height: 22px;
          color: #1c1c1c;
          @include mobile {
            font-size: 16px;
          }
        }
        .text {
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.01em;
          color: #1c1c1c;
          opacity: 0.6;
        }
      }
    }
  }

  .created-date {
    margin-top: 20px;
    >span {
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.005em;
      color: #333333;
      opacity: 0.6;
    }
    @include mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .legends {
      display:none;
      @include mobile {
        display: flex;
      }
    }
  }

  .link {
    cursor: pointer;
    font-size: 14px;
    &:hover {
      color: blue;
    }
  }

  .link-underline {
    cursor: pointer;
    font-size: 14px;
    &:hover {
      color: blue;
      border-bottom: 1px solid blue;
    }
  }

  .view-analytics-btn {
    color: #160b45;
    background: #F7F8FB;
    border: none;
    border-radius: 20px;
    letter-spacing: 0.4px;
  }
}
@import "/src/scss/mixins";

.refresh-form {
  position: relative;
  z-index: 1100;
}

.refresh-signup-modal {
  .ant-modal-content {
    @include mobile {
      top: 0%;
    }
  }
}

.refresh-signup-form-container {
  .info-screen {

    .title {
      text-align: center;
      font-weight: 600;
      font-size: 28px !important;
      line-height: 32px;
      letter-spacing: -1px;
      padding-bottom: 20px;

      &.refresh {
        padding-bottom: unset;
      }
      @include mobile {
        font-weight: 600;
        font-size: 22px !important;
        margin-top: 11px;
      }
    }
    .message {
      color: #6d6d87;
      margin-top: 5px;
      text-align: center;
      font-size: 18px;
      padding-bottom: 10px;
      @include mobile {
        font-weight: 600;
        font-size: 16px !important;
        margin-top: 11px;
      }
    }
    .ant-btn-primary {
      width: fit-content;
      min-height: 43px !important;
      height: 43px !important;
      padding: 0 30px !important;
      margin-top: 40px;
      @include tablet {
        margin-top: 40px;
        width: 100%;
      }
      @include mobile {
        margin-top: 40px;
        width: 100%;
      }
    }
  }
  .second-half {
    background: var(--text-white);
    padding: 0 5.5vw 0 4vw;

    &.refresh {
      padding: unset;
    }
  
    @include tablet {
      width: 100%;
      padding: 40px 24px;
    }
  
    @include mobile {
      width: 100%;
      padding: 40px 19px;
      height: fit-content;
    }
  
    .back-btn {
      position: relative;
      left: -50%;
      span {
        position: relative;
        bottom: 5px;
        vertical-align: text-top;
        margin-right: 8px;
      }
    }
  
    .login-form {
      width: 100%;
      color: black;
      display: flex;
      flex-direction: column;
      align-items: center;
  
  
      @include tablet {
        margin: 0;
      }
      @include mobile {
        margin-top: 0px;
      }
  
      .text-content-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include mobile {
          margin-bottom: 50px !important;
        }
        h2 {
          @include mobile {
            font-weight: 600;
            font-size: 22px !important;
            line-height: 26px;
            letter-spacing: -1px;
          }
        }
        p {
          color: #6d6d87;
          margin-top: 5px;
          text-align: center;
          @include mobile {
            font-weight: 600;
            font-size: 15px !important;
            line-height: 18px;
            margin-top: 1px;
          }
        }
      }
  
      .email-wrapper {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .email-field {
          width: 100%;
          max-width: 535px;
          margin-top: 20px;
          @include tablet {
            max-width: unset;
          }
          @include mobile {
            max-width: unset;
          }
        }
  
        @include tablet {
          flex-direction: column;
        }
        @include mobile {
          flex-direction: column;
        }
  
        .ant-btn-primary {
          width: fit-content;
          min-height: 43px !important;
          height: 43px !important;
          padding: 0 30px !important;
          margin-top: 40px;
          @include tablet {
            margin-top: 40px;
            width: 100%;
          }
          @include mobile {
            margin-top: 40px;
            width: 100%;
          }
        }
      }
  
      .register-block {
        @include mobile {
          margin-top: 25px !important;
        }
      }
  
      .link-register {
        border: 1px solid var(--text-white);
        padding: 5px 20px 6px;
        border-radius: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
      }
      .otp-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .otp-container-style {
          width: 100%;
        }
        @include tablet {
          flex-direction: column;
        }
        @include mobile {
          flex-direction: column;
        }
        > button {
          margin-left: 20px;
          margin-top: 20px;
          @include tablet {
            margin: 40px 0 0;
            width: 100%;
          }
          @include mobile {
            margin: 40px 0 0;
            width: 100%;
          }
        }
      }
      .otp-para {
        @include mobile {
          margin-bottom: 40px !important;
        }
      }
    }
    .create-login-account {
      height: 81px;
      padding-left: 25px;
      background: #f5f5fa;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    p {
      color: var(--primary-color);
      span {
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #7d7d7d;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@import "../../../scss/mixins";
@import "../../../scss/variables";

.company-wallet-wrapper {
  border-radius: 40px;
  min-height: 44px;
  width: fit-content;
  padding: 9px 20px;
  color: var(--text-grey);
  background-color: var(--mid-dark-grey);
  margin-left: auto;
  > span {
    color: var(--text-white);
  }

  @include mobile {
    font-weight: 600;
    font-size: 14px !important;
    padding: 7px 15px;
    border-radius: 24px;
    line-height: 30px !important;
  }
}

@import "../../../scss/variables";
@import "../../../scss/mixins";

.pagination-style {
  margin-top: 20px;

  .ant-pagination {
    .ant-pagination-prev,
    .ant-pagination-next {
      .ant-pagination-item-link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: transparent;
        color: var(--dark-color);
        border-radius: 100px;
      }
    }
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      .ant-pagination-item-link {
        min-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-pagination-item-container {
          width: 100%;
          min-height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .ant-pagination-item-link-icon {
          color: var(--dark-color);
        }
        .ant-pagination-item-ellipsis {
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--dark-color);
        }
      }
    }
    .ant-pagination-item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: transparent;
      color: var(--dark-color);
      border-radius: 100px;
      line-height: 32px;

      @include mobile {
        margin-right: 0;
      }

      a {
        width: 100%;
        height: 100%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
      }
    }

    .ant-pagination-item-active {
      background: var(--primary-color);

      a {
        color: var(--text-white);
      }
    }
  }
  .ant-pagination-options {
    display: none;
  }
}

.bucket {
    &:not(:first-child) {
        margin-top: 48px;
    }
    .img-icon {
        width: 42px;
        margin-right: 20px;
    }

    .icon-heading {
        display: flex;
        align-items: center;
        margin-bottom: 44px;
    }

    .traits {
        &>div:not(:last-child) {
            margin-bottom: 28px;
        }
    }
}

@import "../../../scss/mixins";
@import "../../../scss/variables";

.listing {
  border: 1px solid var(--medium-cream-color);
  overflow-x: auto;

  .table-header {
    color: #636380 !important;
    background: var(--text-white);
    padding: 12px 14px;
    min-width: 100%;
    opacity: 0.9;
    &-test-case-header {
      background: var(--bg-grey);
    }

    > div {
      flex: 1;
    }
    > div:nth-child(2) {
      flex: 0.6;
    }
    > div:nth-child(1) {
      flex: 1.5;
    }
  }
  .table-rows {
    background: var(--text-white);
    color: var(--dark-color) !important;
    padding: 14px !important;
    border: 1px solid #f0f0f0;
  }
  .accordian-table-rows {
    background: var(--text-white);
    color: var(--dark-color) !important;
    padding: 16px 14px !important;
    border-bottom: 1px solid #d9d9d9;
    @include mobile {
      margin-bottom: 18px;
    }
  }
  .action {
    min-height: 30px;
    padding: 0px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    white-space: nowrap;

    &.gameCompleted {
      color: #66e7d1;
      background: rgba(102, 231, 209, 0.1);
    }
    &.rejected {
      color: #d8d8d8;
      background: rgba(32, 12, 12, 0.1);
    }
    &.gameStarted {
      color: #ff864e;
      background: rgba(#ff864e, 0.1);
    }
    &.invited {
      color: #f2c84c;
      background: rgba(255, 202, 75, 0.1);
    }
    &.viewReport {
      color: #d8d8d8;
      border: 1px solid var(--text-white);
      background: transparent;
    }
  }
  .status {
    // padding: 5px 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    white-space: nowrap;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &.pending {
      color: #ff864e;
    }
    &.gameCompleted {
      color: #66e7d1;
      background: rgba(102, 231, 209, 0.1);
      cursor: pointer;
      &:hover {
        color: #4fb9a8;
      }
    }

    &.rejected {
      color: #acacac;
    }

    &.generated {
      color: var(--text-white);
      background: var(--primary-color);
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      border-radius: 30px;
      vertical-align: text-top;

      &:hover {
        background-color: var(--primary-color);
      }
    }

    &.cancelled {
      color: rgb(138, 138, 138);
    }
  }
  .dropdownmenu {
    right: 14px;
  }
}

.list-container {
    width: 100%;
    background-color: #f7f7ff;
    padding: 15px 17px;
    border-radius: 10px;
}

.first-ul {
    margin-top: 4px;
}

.ul {
    margin-top: 14px;
}

.heading {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #3e3e60;
    opacity: 1;
    margin-bottom: 6px !important;
    line-height: 20px;
    letter-spacing: 0.42px;
}

.list-item {
    font-size: 14px;
    font-weight: 400;
    color: #3e3e60;
    opacity: 0.95;
    line-height: 20px;
    letter-spacing: 0.22px;
}

// CSS Global Variables
:root {
  // --primary-color: #7e2eff;
  --primary-color: #1c1c1c;
  --secondary-color: #f3ddc2;
  --error-color: #ff5267;
  --container-bg: #f5f7fd;
  --main-container-bg: #fffbf6;
  --bg-white: #fff;
  --bg-grey: #eceff2;
  --dark-color: #1c1c1c;
  --light-dark-color: #313131;
  --mid-dark-color: #2a2a2a;
  --mid-dark-grey: #323232;
  --avg-dark-color: #3a3a3a;
  --text-grey: #7a7a8d;
  --text-white: #ffffff;
  --cream-white: #e5e5e5;
  --medium-text-grey: #7d7d7d;
  --maroon-color: #cf3b01;
  --medium-cream-color: #ebd8c1;
  --light-grey: #f3f3f3;
  --dark-blue: #160b45;
  --light-brown: #dcbc97;

  // report colors
  --black-100: #2e2e2e;
  --tuft-bush-tint: #ffd3b980;
}

// Responsive Breakpoints

// 320px — 480px: Mobile devices
// 481px — 768px: iPads, Tablets
// 769px — 1024px: Small screens, laptops
// 1025px — 1200px: Desktops, large screens
// 1201px and more —  Extra large screens, TV

$tabs: "only screen and (min-width : 481px) and (max-width: 768px)";
$slarge: "only screen and (min-width : 769px) and (max-width: 1024px)";
$large: "only screen and (min-width : 1025px) and (max-width: 1200px)";
$xlarge: "only screen and (min-width : 1201px)";

.admin-card {
  padding: 10px;
  border: 1px solid var(--secondary-color);
  width: 100%;
  background: var(--text-white);

  .member-details {
    .ant-avatar {
      height: 44px;
      width: 44px;
    }
    .name {
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      letter-spacing: -1px;
      color: #160b45;
    }
    .designation {
      // margin-top: 3px;
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: -0.02em;
      color: #7a7a8d;
    }
  }

  img {
    height: 15px;
    cursor: pointer;
  }
  .action {
    margin-left: auto;
  }
  .email {
    font-size: 16px;
    font-weight: 400;
    color: var(--text-grey);
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    padding-top: 33px;
  }
  .customize-notifications {
    svg {
      width: 16px;
      height: 16px;
    }
    span {
      color: black;
    }
  }
}

@import "/src/scss/mixins";

.select-organization {
  .organizations {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
  @include mobile {
    &.container {
      padding: 30px;
    }
    .organizations {
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }
  .unberry-logo > svg {
    height: 35px;
    width: fit-content;
  } 
  .organization-card {
    grid-template-columns: 70px 1fr 30px;
    img {
      border-radius: 50%;
    }
    .ant-avatar,
    img {
      height: 60px;
      width: 60px;
      border: 1px solid #eeeeee;
    }
    border: 1px solid #e3e3e3;
    border-radius: 5px;
  }
}

.user-profile {
  gap: 15px;
  margin-top: 110px;
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }
  .name-initial {
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #332e44;
    width: 100%;
    color: white;
    font-weight: 600;
    height: 100%;
    text-transform: uppercase;
  }
}

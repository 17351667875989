@import "../../../scss/mixins";
@import "../../../scss/variables";
.filter-component {
  position: relative;
  .mobile-filter-icon {
    display: none;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #eee3d6;
    padding: 14px;

    @include mobile {
      display: inline-block;
    }
  }
  .mobile-filter-count {
    display: none;
    position: absolute;
    top: 65%;
    left: -10%;
    background: #000000;
    color: #ffffff;
    border-radius: 50%;
    padding: 1px 8px 2px 8px;
    @include mobile {
      display: inline-block;
    }
  }

  .background-overlay {
    display: none;
    @include mobile {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000000CC;
      z-index: 1;
      &.hide {
        display: none;
      }

    }
  }

  .filters {
    @include mobile {
      // display:none;
      position: fixed;
      width: 100%;
      height: fit-content;
      bottom: 0;
      left: 0;
      z-index: 2;
      background: #fff;
      transition: 0.2s transform ease;
      transform: translateY(100%);

      &.open {
        padding: 0 0 24px;
        transform: translateY(0%);
        box-shadow: 0px -4px 10px 5px #0001;
        // &::before {
        //   content: '';
        //   top: 0;
        //   bottom: 0;
        //   left: 0;
        //   right: 0;
        //   background: #000000CC;
        //   position: fixed;
        //   z-index: -1;
        // }
      }
    }
    .ant-select-arrow {
      right: 8px;
    }

    .mobile-filter-header {
      display: none;
      padding: 18px;
      align-items: center;
      justify-content: space-between;
      background: #fffbf6;
      @include mobile {
        display: flex;
      }
      > div > h4 {
        margin-left: 5px;
      }
    }

    .item-box {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 10px;
      @include mobile {
        grid-template-columns: 1fr;
        padding: 0 18px;
      }

      .ant-select-selection-item {
        padding: 0 8px 0 4px !important;
        width: 108px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .ant-select-selector {
        border-radius: 40px;
        text-align: center;
        background: var(--text-white) !important;
        font-size: 13px !important;
        font-weight: 600 !important;
        color: #2e1500 !important;
        padding: 12px 15px 12px 0px !important;
        box-shadow: none !important;
        height: fit-content !important;
        @include mobile {
          border-radius: 0;
          border: none !important;
          border-bottom: 1px solid #eee3d6 !important;
        }
      }
    }
    .ant-select-dropdown {
      border: none !important;
      border-radius: 8px;
      padding: 0;
    }
    .filter-select {
      position: relative;
      &.ant-select-open {
        .ant-select-selector {
          outline: none !important;
          box-shadow: 0px 0px 10px 2px #0002 !important;
          font-size: 13px;
        }
        .ant-select-selection-item {
          color: #2e1500 !important;
        }
      }
    }
    .ant-dropdown-menu > li > span {
      margin-left: 0px;
    }
    .ant-dropdown-trigger,
    .ant-dropdown {
      border-radius: 40px;
      box-sizing: border-box;
      border: 1px solid #EEE3D6;
      text-align: center;
      background: var(--text-white) !important;
      font-size: 18px !important;
      font-weight: 600 !important;
      color: #A8A8A8 !important;
      padding: 14px 10px 14px 20px !important;
      // box-shadow: none !important;
      height: fit-content !important;
      width: max-content !important;
      @include mobile {
        width: 100% !important;
        border-radius: 0;
        border: none !important;
        border-bottom: 1px solid #eee3d6 !important;
      }

      .ant-space {
        display: flex;
        justify-content: flex-end;
        .ant-space-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          .anticon-down {
            padding: 4px 0px 0px 15px;
            color: #2e1500;
    
            @include mobile {
              margin-left: 32vw;
            }
    
            > svg {
              height: 12px;
              width: 12px;
            }
          }
        }
      }

      
    }
  }
}

.collapse-container {
  background-color: #fff;

  .header {
    margin-bottom: 5px;
    position: relative;

    & + .header {
      margin-top: 30px;
    }

    &:after {
      content: "";
      border: 6px solid #ffffff;
      border-left-color: transparent;
      border-bottom-color: transparent;

      position: absolute;
      top: 0;
      right: 0;
    }

    .avatar > span {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: -1px;
    }

    .header-content {
      width: 95%;
      .subHeading {
        font-size: 14px;
        margin-top: 5px;
      }
      span {
        border-radius: 50%;
        min-width: 30px;
        min-height: 30px;
      }
    }

    .ant-collapse-header {
      padding: 12px 16px !important;
    }
    .ant-collapse-content {
      padding: 0 !important;

      .container {
        border-bottom: 1px solid #ddddec;
        padding: 12px 0 !important;

        .organization-logo,
        .ant-avatar {
          width: 36px;
          height: 36px;
        }
      }
      .ant-collapse-content-box {
        padding: 0 !important;
      }
    }
  }
  .collapse-arrow {
    border-radius: 20px;
    filter: brightness(6);

    &.rotate {
      transform: translateY(-50%) rotate(180deg) !important;
    }
  }
}

.email-field {
  .ant-form-item-children-icon {
    top: 45% !important;
  }
}

.invite-candidate-modal {
  .ant-btn-primary {
    width: 100%;
  }
}

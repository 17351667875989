.schedule-invite-form {
	width: 100%;


	.ant-picker-container {
		.ant-picker {
			width: 100% !important;
		}
	}
}

.ant-picker-panel {
	.ant-picker-time-panel {
		.ant-picker-content {
			.ant-picker-time-panel-column {
				width: 93px;
			}
		}
	}
}


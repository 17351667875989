.traits-drawer-container {
  .ant-drawer-content-wrapper {
    min-width: 524px !important;
    .drawer-content-wrapper {
      padding: 40px 40px 80px;
    }
    // .ant-drawer-content{
    //   padding: 40px;
    //   padding-right: 0;
    // }

    .ant-drawer-header {
      border-bottom: none !important;
    }
  }

  .closeIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
}

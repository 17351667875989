@import "/src/scss/mixins";

.filter-icon-component {
  .filter-bars {
    width: 14px;
    height: 2px;
    background: #000;
    margin: auto;
    & + .filter-bars {
      margin-top: 3px;
    }
    &:nth-of-type(2) {
      width: 10px;
    }
    &:nth-of-type(3) {
      width: 6px;
    }
  }
}



.p-component {
  margin-top: 20px;
  position: relative;
  .p-header {
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    display: flex;
    flex: 1 1;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
    z-index: 10;
    @media (max-width: 1440px) {
      top: -30px;
    }

    .title {
      width: 45%;
      font-size: 26px;
      font-weight: 600;
    }
  }
  .p-traits {
    // height: 300px;
    overflow-y: auto;
  }
}

.p-trait-group {
  border: 1px solid #9D9D9D;
  border-radius: 20px;
  margin: 16px 0;
  
  div:first-child {
    border-radius: 20px 20px 0px 0px;
  }

  div:last-child {
    border-radius: 0px 0px 20px 20px;
  }
}

.p-traits-container {
  flex: 1 1;
  height: 140px;
  border-bottom: 1px dashed #BABACD;
  &.parent-trait {
    border-bottom: 1px solid #BABACD;
    background: #F6F6F7;
  }

  .trait-definition {
    width: 48%;
    padding-left: 4%;

    h4 {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 900;
      &.parent-trait {
        text-transform: unset;
        font-size: 21px;
        font-weight: 600;
        letter-spacing: -1px;
        width: fit-content;
        border-radius: 11px;
        color: white !important;
        padding: 8px
      }
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: -0.03em;
      text-align: left;
      color: #6d6d85;
      margin-top: 9px;
      width: 70%;
    }
  }
  

  .trait-bar-container {
    width: 50%;
    height: 100%;
    position: relative;
    right: 2%;
    .trait-bar-marker {
      width: 2px;
      height: 20px;
      position: relative;
      bottom: 15px;
      background-color: #E2E7EF;
      left: 0;
      z-index: 1;
    }
    .bar-marker {
      position: absolute;
      top: 45px;
      color: #ABABAB;
      z-index: 2;
      &.lb {
        left: 1.4%;
      }
      &.ub {
        right: 1.4%;
      }
    }
    
    .trait-bar {
      width: 100%;
      height: 24px;
      background-color: #E2E7EF;
      border-radius: 2px;
      position: relative;
      margin-top: 10px;

      .bar-progress {
        height: 100%;
        background-color: rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.3s ease-in-out;
      }
      .median-marker {
        width: 3px;
        height: 100%;
        position: relative;
        top: 0;
        background-color: #9F72FF;
        left: 0;
        z-index: 1;
      }
      .score-marker-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 21px;
        height: 21px;
        border-radius: 50%;
        background-color: white;
        filter: contrast(2);
        position: relative;
        bottom: 94%;
        left: 0;
      }
      .score-marker {
        width: 17px;
        height: 17px;
        border-radius: 50%;
        background-color:#2B2A2F;
        z-index: 2;
      }
    }
  }
}

.step-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 54%;
  position: absolute;

  .step {
    position: relative;
    .line {
      position: absolute;
      top: 50%;
      left: -50%;
      height: 1px;
      width: 100%;
      transform: translateX(-50%);
      background-color: rgb(0, 0, 0, 0.1);
      z-index: -1;
    }
    
    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: rgba(243, 243, 243, 0.945);
      font-size: 16px;
      color: rgb(0, 0, 0, 0.4);
      font-weight: bold;
    }
  }
}

@import "../../scss/variables";
@import "../../scss/mixins";

.mydna-container {
  position: relative;
  background: var(--main-container-bg);
  color: black;

  .blank-message {
    margin-top: 6%;
  }

  @include mobile {
    // margin-top: 72px;
  }

  .container {
    @include desktop-lg {
      // max-width: 1440px !important;
    }
    @include mobile {
      padding: 0;
    }
  }

  .content-wrapper {
    padding: 0;

    @include rwd(1280) {
      padding: 0;
    }
    @include tablet {
      padding: 0;
    }
    @include mobile {
      padding: 0 0 90px;
    }
  }
  // .heading-block {
  //   padding: 40px 0 0 0;
  //   border-bottom: 1px solid #ebd8c1;

  //   @include mobile {
  //     flex-direction: column-reverse;
  //   }

  //   .btn-box {
  //     @include mobile {
  //       width: 100%;
  //       position: fixed;
  //       bottom: 0;
  //       z-index: 1;
  //       padding: 20px 12px;
  //       background: var(--dark-color);
  //     }

  //     .ant-btn {
  //       @include mobile {
  //         width: 100%;
  //       }
  //     }
  //   }
  //   .gameplay-count {
  //     color: #dcbc97;
  //     vertical-align: text-top;
  //   }
  // }

  .card-container {
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mobile {
      padding: 18px;
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .blank-listing-img {
    height: 200px;
  }
  .position-type {
    // background: red;
    border-radius: 15px;
    // display: inline;
  }
  .action-btn {
    border-radius: 15px;
    background: var(--dark-color);
  }

  .item-box {
    grid-column-gap: 40px;
    grid-template-columns: repeat(2, 1fr);

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .empty-state-cont {
    height: calc(100vh - 72px);
  }
}

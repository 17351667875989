.audio-player-container {
  background: #e9e9e9;

  button {
    background: none;
    border: none;
  }

  .audio-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    border-radius: 8px;
  }

  .audio-slider::-webkit-slider-thumb {
    border-radius: 8px;
    -webkit-appearance: none;
    appearance: none;
    width: 3px;
    height: 20px;
    background: white;
    cursor: pointer;
    transition: all ease 0.1s;
  }

  .audio-slider::-moz-range-thumb {
    border-radius: 8px;
    width: 3px;
    height: 25px;
    background: white;
    cursor: pointer;
    transition: all ease 0.1s;
  }
}

@import "/src/scss/variables";
@import "/src/scss/mixins";

.position-container-form {
  background: var(--primary-color);
  height: 100vh;

  .first-half {
    height: 30%;
    width: 100%;
    background: linear-gradient(172deg, #69504B 0%, rgba(0,0,0,1) 56%);
    padding: 0 30px 40px;
    display: flex;
    align-items: flex-end;

    .heading {
      color: var(--text-white);
      font-size: 32px;
      font-weight: 600;
    }
  }
  .second-half {
    height: 70%;
    background: var(--bg-white) !important;
    padding: 30px 30px 0;
    position: relative;
    .position-form {
      height: 100%;
      position: relative;
      form {
        height: 100%;
        .content-wrapper {
          height: calc(100% - 73px);
          overflow: auto;
        }
        .ant-select-selector {
          padding-top: 0 !important;
          color: black !important;
        }
        .ant-select-multiple .ant-select-selection-item {
          height: unset;
        }
        .ant-select-selection-item {
          padding: 6px !important;
          margin: 4px;
      }
      }
    }

    .content-heading {
      border-top: 2px solid var(--primary-color);
      font-weight: 900 !important;

      span {
        color: #a5a5a5;
      }
    }

    .btn-box {
      position: absolute;
      border-top: 1px solid #dbdbdb;
      width: 100%;
      left: 0;
      bottom: 0;
      padding: 14px 0;
      gap: 15px;

      .ant-btn-primary,
      .ant-cancel-btn {
        height: 44px !important;
      }
      .ant-cancel-btn {
        > svg {
          vertical-align: baseline;
          margin-right: 5px;
        }
      }
    }

    .ant-form-item-children-icon {
      top: 45% !important;
    }
  }
}

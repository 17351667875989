.about-traits {
  display: grid;
  grid-template-columns: 1fr 4fr !important;
  gap: 0 45px;
  .traits-description {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 0 60px;
    .line {
      grid-column: 1 / -1;
      height: 1px;
      background: #e0e0e0;
      margin: 8px 0;
    }
  }
  .skill-badge {
    float: left;
    width: 35px;
    height: 18px;
    display: block;
    border-radius: 10px;
    margin-right: 10px;
  }

  .left-item {
    display: flex;
  }

  .img-sec {
    width: 46px;
    margin-right: 20px;
  }
}

@import "../../scss/variables.scss";
@import "../../scss/mixins";

.login-container {
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  color: var(--text-white);

  @include tablet {
    flex-direction: column;
  }

  @include mobile {
    flex-direction: column;
    height: fit-content;
  }

  .first-half {
    background: var(--dark-color);
    background: radial-gradient(circle at 15% -30%, #69504b, #1c1c1c 55%);
    height: 100%;
    width: 50%;
    padding: 25px 25px 25px 70px;

    @include desktop-lg {
      padding: 25px 25px 25px 70px !important;
    }

    @include desktop {
      padding: 25px 30px;
    }

    @include tablet {
      width: 100%;
      // padding: 10px 0px;
      padding: 24px 24px 40px;
      height: 100%;
    }

    @include mobile {
      width: 100%;
      padding: 24px 19px 40px;
      height: min-content;
    }

    .gradient {
      width: 250px;
      height: 200px;
    }

    .logo-container {
      > svg {
        @include mobile {
          width: 70px;
        }
      }
    }

    .image-container {
      text-align: right;
      margin-top: 60px;
      overflow: hidden;

      @media (min-width: "1024px") and (min-height: "580px") and (max-height: "700px") {
        margin-top: 0px;
      }
      @media (min-width: "1024px") and (min-height: "400px") and (max-height: "580px") {
        margin-top: -100px;
      }
      @include tablet {
        margin-top: -15px;
        margin-right: -24px;
      }
      @include mobile {
        margin-top: -15px;
        margin-right: -19px;
      }
      > svg {
        width: 90%;
        @include tablet {
          width: 70%;
          height: auto;
          margin-right: -20px;
        }
        @include mobile {
          width: 70%;
          height: auto;
          margin-right: -13px;
        }
      }
    }

    .hero-text {
      .heading {
        // font-size: 86px;
        // line-height: 83px;
        width: min-content;
        font-size: 78px;
        line-height: 75px;
        margin-top: -100px;
        margin-left: -6px;
        letter-spacing: -4px;

        @include tablet {
          font-weight: normal;
          // font-size: 50px;
          // line-height: 76px;
          // letter-spacing: -2px;
          width: 100%;
        }

        @include mobile {
          font-weight: normal;
          font-size: 50px;
          line-height: 76px;
          letter-spacing: -2px;
          width: 100%;
          margin-top: -50px;
        }
      }

      .description {
        padding-top: 15px;
        width: 270px;
        color: #e1ddef;
        font-size: 18px;
        font-weight: 200;
        line-height: 29px;

        // @include desktop-lg {
        //   padding-top: 15px;
        // }

        // @include rwd(1280) {
        //   font-size: 30px;
        // }

        @include tablet {
          font-size: 20px;
          // width: 100%;
        }
        @include mobile {
          font-size: 18px;
          line-height: 24px;
          padding-top: 0;
        }
        // @include iphone5 {
        //   font-size: 16px;
        //   line-height: 20px;
        // }
      }
    }

    .hero-default-text {
      .description {
        margin-top: -120px;
        width: 300px;
        color: #e1ddef;
        font-size: 40px;
        font-weight: 200;
        line-height: 40px;

        
        @media (min-width: "1024px") and (min-height: "400px") and (max-height: "700px") {
          width: 270px;
          font-size: 26px;
          line-height: 26px;
          padding-top: 0;
        }

        @include tablet {
          margin-top: -140px;
          font-size: 36px;
        }
        @include mobile {
          margin-top: 0px;
          font-size: 28px;
          line-height: 32px;
          padding-top: 0;
        }
      }
    }
  }

  .second-half {
    height: 100%;
    width: 50%;
    background: var(--text-white);
    padding: 0 5.5vw 0 4vw;
    // @include rwd(1024) {
    //   width: 100%;
    // }

    @include tablet {
      width: 100%;
      padding: 40px 24px;
    }

    @include mobile {
      width: 100%;
      padding: 40px 19px;
      height: fit-content;
      // &:focus-within {
      //   top: 20% !important;
      // }
    }

    .back-btn {
      span {
        vertical-align: text-top;
        margin-right: 8px;
      }
    }

    .login-form {
      width: 100%;
      margin-top: 32vh;
      color: black;

      // @include rwd(1024) {
      //   margin-top: 50px;
      //   padding: 0 4%;
      // }

      @include tablet {
        margin: 0;
      }
      @include mobile {
        margin-top: 0px;
        // max-width: 100%;
        // padding: 25px !important;
        // max-height: 100%;
        // min-height: 100%;
      }

      .text-content-block {
        @include mobile {
          margin-bottom: 50px !important;
        }
        h2 {
          @include mobile {
            font-weight: 600;
            font-size: 22px !important;
            line-height: 26px;
            letter-spacing: -1px;
          }
        }
        p {
          color: #6d6d87;
          margin-top: 5px;
          @include mobile {
            font-weight: 600;
            font-size: 15px !important;
            line-height: 18px;
            margin-top: 1px;
          }
        }
        // @include mobile {
        //   margin-bottom: 25px !important;
        // }
      }

      .email-wrapper {
        .email-field {
          width: 100%;
          max-width: 535px;
          @include tablet {
            max-width: unset;
          }
          @include mobile {
            max-width: unset;
          }
        }

        // @include rwd(1024) {
        //   flex-direction: column;
        // }

        @include tablet {
          flex-direction: column;
        }
        @include mobile {
          flex-direction: column;
        }

        .ant-btn-primary {
          width: fit-content;
          min-height: 43px !important;
          height: 43px !important;
          padding: 0 30px !important;

          // @include rwd(1024) {
          //   margin-top: 20px;
          // }
          @include tablet {
            margin-top: 40px;
            width: 100%;
          }
          @include mobile {
            margin-top: 40px;
            width: 100%;
          }
        }
      }

      .register-block {
        @include mobile {
          margin-top: 25px !important;
        }
      }

      .link-register {
        border: 1px solid var(--text-white);
        padding: 5px 20px 6px;
        border-radius: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;

        // @include mobile {
        //   margin: 20px 0 0 !important;
        // }
      }
      .otp-form {
        @include tablet {
          flex-direction: column;
        }
        @include mobile {
          flex-direction: column;
        }
        > button {
          margin-left: 20px;
          @include tablet {
            margin: 40px 0 0;
            width: 100%;
          }
          @include mobile {
            margin: 40px 0 0;
            width: 100%;
          }
        }
      }
      .otp-para {
        @include mobile {
          // font-size: 15px!important;
          margin-bottom: 40px !important;
        }
      }
    }
    .create-login-account {
      height: 81px;
      padding-left: 25px;
      background: #f5f5fa;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    p {
      color: var(--primary-color);
      span {
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
  .otp-text {
    margin: 37px 0 34px !important;
  }
}

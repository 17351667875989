.trait-fill-block-component {
  height: 28px;
  flex: 1;
  border-radius: 2px;
  position: relative;
  .user-scores {
    position: absolute;
    top: 4px;
    .score-string {
      position: relative;
      top: 1px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.1px;
    }
  }
  .median {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
  .line {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    border-left: 1.4px dashed #160b45;
    z-index: 0;
    transform: translateX(-50%);
  }
  .icon {
    > svg {
      position: absolute;
      transform: translateY(-50%) translateX(-50%);
      top: 0;
      left: 50%;
      width: 16px;
    }
  }
}

.spider-chart {
  svg {
    width: 350px;
  }
}
.legends {
  gap: 15px;
  .flex {
    gap: 10px;
  }
  .legend-color {
    width: 13.5px;
    height: 13.5px;
    border-radius: 5px;
  }
}

@import "../../../scss/mixins";
@import "../../../scss/variables";

.report-generator {
  grid-template-columns: 4fr 1fr 1fr;
  justify-items: start;
  align-items: center;
  height: 65px;

  .background-overlay {
    display: none;
    @include mobile {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000000cc;
      z-index: 3;
      &.hide {
        display: none;
      }
    }
  }

  .status-mt {
    margin-top: 0.125rem;
  }
  .score-pill {
    padding: 8px 12px !important;
    border-radius: 50px;
    width: fit-content;
    border: none !important;
  }
  .status-pill {
    padding: 8px 15px;
    border-radius: 50px;
    display: flex;
    width: min-content;
    min-width: 100px;
  }
  .score-pill > p,
  .status-pill > p {
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    text-transform: uppercase;
  }
}
.optionmenu {
  .view-report-icon {
    transform: rotate(45deg);
  }
  .empty-icon {
    margin-right: 5px !important;
  }
  @include mobile {
    transition: 0.2s transform ease;
    transform: translateY(100%);

    &.open {
      transform: translateY(0%);
      box-shadow: 0px -4px 10px 5px #0001;
    }
  }
  .reset-disqualification-icon {
    top: -5.5px;
  }
  .cancel-invite-icon > span {
    top: -5px;
  }
}

@import "../../../scss/mixins";
@import "../../../scss/variables";

.scroll-to-top {
  background-color: var(--primary-color);
	padding: 16px;
	border-radius: 50%;
	cursor: pointer;

	position: fixed;
	bottom: 100px;
	right: 20px;

	@include mobile {
		bottom: 25px;
	}
}
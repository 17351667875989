.notFound-component {
  display: flex;
  gap: 12px;
  .icon-wrapper {
    padding: 11px;
    background-color: #ededed;
    border-radius: 50%;
    width: 54px;
    height: 54px;
    .icon > svg {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .heading {
      background: linear-gradient(90.92deg, #bebebe 4.54%, #f3f3f3 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
    }
    .card {
      background: linear-gradient(90.92deg, #ededed 4.54%, #f8f8f8 100%);
      height: 16px;
      border: none;
      border-radius: 0;
      & + .card {
        width: 90%;
      }
    }
  }
}

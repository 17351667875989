@import "../../../scss/variables.scss";
@import "../../../scss/mixins";

.float-label {
  position: relative;
}

.label {
  color: #8795a2;
  font-size: 16px;
  font-weight: 500;
  opacity: 1;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 50%;
  transition: 0.2s ease all;
  transform: translateY(-50%);

  @include iphone5 {
    font-size: 12px;
    top: 18px;
  }
}

.label-float {
  top: 25%;
  font-size: 12px;
}

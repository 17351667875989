.skills-side-menu {
    max-width: 300px;
    border-radius: 15px;
    background-color: #fff;
    padding: 22px 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;

    & .heading {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 19px;
        color: #fff;
        background-color: #6564db;
        border-radius: 30px;
        padding: 3px 12px;
        margin-bottom: 16px !important;
    }

    & .description {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #202020;
        opacity: 0.7;
        margin-bottom: 20px !important;

        &-heading {
            position: relative;
            bottom: 5px;
        }
    }

    & .button {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;
        color: #ef673e;
        cursor: pointer;
        margin-top: 20px;
    }
}

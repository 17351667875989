@import "../../../scss/variables";
@import "../../../scss/mixins";

.otp-container-style {
  width: 40%;
  justify-content: space-between;

  @include rwd(1460){
    width: 80%;
  }

  @include rwd(1024){
    width: 80%;
  }

  @include mobile{
    width: 100%;
  }

  .otp-input-style {
    min-width: 60px;
    min-height: 44px;
    background: var(--text-white);
    border: none;
    border-bottom: 2px solid var(--text-grey) !important;
    font-size: 22px;
    line-height: 26px;
    font-weight: 600;
    color: var(--dark-color);

    @include mobile {
      min-height: 60px;
      min-width: 60px;
      font-size: 36px;
    }

    @include iphone5 {
      min-height: 40px;
      min-width: 40px;
      font-size: 20px;
    }

    &:focus-visible {
      outline: none;
    }
  }
}

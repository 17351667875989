@import "src/scss/mixins";
@import "../../../scss/variables";

$violetblue: #365ce3;

.display-question {
  width: 90%;
}

.content-section {
  max-width: 100vw;
}

.questions-section {
  border-top: 1px solid #babacd;
  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .content {
      display: flex;
      align-items: center;
      justify-content: start;
      h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0px;
        text-align: left;
        color: #2a2a41;
        margin-right: 10px;
      }
      .vertical-spacer {
        @include mobile {
          display: none;
        }
      }
      .overview {
        margin-left: 10px;
        @include mobile {
          margin-left: unset;
          position: relative;
          bottom: 10px;
        }
      }
      p {
        position: relative;
        top: 3px;
        height: 22px;
      }
    }
    .display-section {
      cursor: pointer;
    }
  }
}

.question-content-text {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.65px;
  line-height: 20px;
}

.section-content-item {
  padding: 20px 0;
  .question-content {
    > ol {
      list-style: decimal;
      margin-left: 20px;
    }
    > ul {
      list-style: decimal;
      margin-left: 20px;
    }
    > p,
    li,
    ul,
    span {
      @extend .question-content-text;
    }
    pre {
      @extend .question-content-text;
      white-space: break-spaces;
    }
    code {
      @extend .question-content-text;
      white-space: break-spaces;
    }
    table {
      border-collapse: collapse;
    }
    
    th,td {
      border: 1px solid black;
      padding: 8px;
    }
    
    th {
      text-align: center;
      background-color: #f2f2f2;
    }
  }
  .answer-content-html {
    @extend .question-content;
    > p {
      margin-bottom: 12px !important;
    }
  }
  .display-question {
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.5px;
    max-width: 120px;
    color: $violetblue;
    &-icon {
      position: relative;
      top: 2px;
      font-size: 12px;
    }
  }
}

.section-content-header {
  .difficulty-text,
  .difficulty-level {
    color: #8a8a8a;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
  }
  .difficulty-level {
    color: #2e2e2e;
  }
  .header-end {
    // width: 160px;
    gap: 8px;
    .score-box {
      max-width: 100px;
      font-size: 16px;
      padding: 10px;
      box-sizing: border-box;
      border-radius: 70px;
      border: 1px solid #babacd;
      color: #565658;
      &.correct {
        border: 1px solid #00590e;
        color: #00590e;
      }
      &.wrong {
        border: 1px solid #da4c5f;
        color: #da4c5f;
      }
    }
    .score-indicator {
      svg {
        font-size: 36px;
      }
      &.correct {
        svg {
          color: #00590e;
        }
      }
      &.wrong {
        svg {
          color: #da4c5f;
        }
      }
    }
  }
}

.score-pills-container {

  display: flex;
  align-items: center;

  @include mobile{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .score-pill-wrapper {
    background: #e9e9e9;
    border-radius: 40px;
    padding: 10px 20px;
    margin-top: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 10px;
    
    @include mobile{
      display: flex !important;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 20px;
      border-radius: unset;
    }
    .pill-type {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #868686;
    }
  }
}



.question-time-taken {
  justify-content: flex-end;
  font-weight: 600;
  color: #868686;
  @include mobile {
    font-weight: 600;
    font-size: large;
    justify-content: flex-start;
    margin-bottom: 14px;
  }
}

.score-pill {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #868686;

  @include mobile{
    margin: 3px 0;
  }

  &:not(:last-of-type) {
    padding-right: 6px;
    margin-right: 6px;
    border-right: 1px solid #868686;

    @include mobile{
      padding-right: unset;
      margin-right: unset;
      border-right: unset;
    }
  }

  & > .score {
    color: #202020;
  }
}

.section-content-answer {
  .options {
    font-size: 16px;
    line-height: 26px;
    font-style: normal;

    .options-map {
      display: flex;
      @include mobile {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
    .options-header,
    .options-content {
      font-weight: 600;
      color: #505050;

      .option-char {
        color: #555555de;
      }
    }
    .options-text {
      &.ws {
        width: 80%;
      }
    }
    .options-marked {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      // border: 2px solid #FFFFFF;
      border-radius: 2px;
      padding: 3px 5px;
      color: #fff;
      &.correct {
        background: #60ecb1;
      }
      &.semi-correct {
        background: #ffdb8f;
      }
      &.wrong {
        background: #d96868;
      }
    }
  }
  .program {
    .program-header {

      @include mobile {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        gap: 20px 60px;
      }
      color: #2a2a41;
      &-heading {
        font-weight: 400;
      }
      &-value {
        font-weight: 800;
        font-size: 16px;
        line-height: 22px;
        text-transform: uppercase;
      }
      &.w90 {
        width: 90%;
      }
      &.w80 {
        width: 80%;
      }
      .program-content-toggle {
        cursor: pointer;
        color: $violetblue;
        font-weight: 700;
      }
      .vertical-spacer {
        display: none;
      }
    }
  }

  .view-chat-history {
    color: $violetblue;
    font-weight: 700;

    span.right-outlined {
      font-size: 12px;
    }
  }
}

.chat-messages-container {
  max-height: 600px;
  gap: 8px;
  overflow-y: scroll;
  
  .message-content {
    width: fit-content;
    max-width: 70%;
    line-height: 20px;
    font-size: 16px;
    padding: 16px;
    
    &.user-message {
      border-radius: 24px 0 24px 24px;
      background-color: #f9e4cb;
    }

    &.bot-message {
      border-radius: 0 24px 24px 24px;
      background-color: #f2f2f2;
    }
  }
}

.editor {
  counter-reset: line;
  position: relative;
  min-height: 300px;
  max-height: 700px;
  overflow: scroll;
  &-header {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #2a2a41;
  }
  pre {
    line-height: 18px;
  }

  .pre-text {
    padding-left: 80px !important;
    position: absolute;
    background: #f4f6f9;
    width: 100%;
  }
  .pre-line-number {
    z-index: 2;
    position: absolute;
    left: 0px;
    padding-left: 5px;
    color: black;
    text-align: left;
    background: #e0e6ed;
    width: 60px;
    font-weight: 100;
  }
}

// Common

.hidden {
  display: none;
}
.vertical-spacer {
  border-left: 1px solid #d9d9d9;
  height: 27px;
  &.big {
    position: relative;
    bottom: 4px;
    height: 42px;
  }
}
.dot {
  position: relative;
  bottom: 3px;
  margin: 0px 6px;
  height: 4px;
  width: 4px;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;

  @include mobile {
    display: none;
  }
}
.highlighted {
  font-weight: 600;
}
.separator {
  border: 1px dashed #babacd;
}
.wrap-whitespace {
  white-space: normal;
}
.line-break-any {
  line-break: anywhere;
}
.underline-word {
  border-bottom: 1px solid black;
}
.highlight-word {
  border-bottom: 1px solid black;
  font-weight: 600;
  font-size: 18px;
  white-space: nowrap;
}
.question-summary {
  background: rgba(255, 245, 220, 0.4);
  border: 1px solid #ada284;
  border-radius: 10px;
  padding: 16px;
  margin: 24px 0;

  h2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #9c621a;
  }

  p {
    margin-top: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #2a2a41;
  }

  .relevancy {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #868686;

    &.remarks {
      flex-wrap: wrap;
      gap: 12px;
      margin-left: 12px;
      text-align: right;
      width: 80%;

      @include mobile {
        flex-direction: column;
        align-items: flex-end;
      }
    }

    .context-key {
      text-transform: capitalize;

      .marker {
        @include mobile {
          display: none;
        }
      }
    }

    .context-value {
      color: #202020;
    }

    .contextkey-seperator {
      width: 1px;
      height: 14px;
      background: #868686;
    }

    & > span:last-of-type {
      color: #202020;
    }
  }
}

@import "../../../scss/variables";
@import "../../../scss/mixins";

.header-component {
  // height: 70px;
  background: var(--dark-color);
  color: var(--text-white);
  z-index: 100;
  position: sticky;
  top: 0;

  @include mobile {
    padding: 14px 18px;
  }

  .navbar {
    padding: 0 70px;
    width: 100%;
    max-width: 1440px;
    margin: auto;
    @include tablet {
      padding: 12px 70px;
    }
    @include mobile {
      padding: 0;
    }
  }

  /* Override bootstrap class styling */
  .navbar-expand-lg {
    @include rwd-cr(991, 1024) {
      justify-content: space-between;
    }
  }

  .navbar-brand {
    @include rwd(1024) {
      display: none;
    }
    img {
      max-height: 45px;
    }
  }

  .mobile-menu {
    display: none;
    @include rwd(1024) {
      display: flex;
      align-items: center;
      > button {
        margin-right: 10px;
      }
    }
    .current-page-name {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
    }
  }

  .navbar-toggler {
    @include tablet {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0px;
      transition: 0.3s transform ease-out;

      &:focus {
        box-shadow: none;
      }

      .navbar-toggler-icon {
        transition: 0.3s transform ease-out;
      }

      &.show {
        transform: rotate(180deg);
        -webkit-transform: -webkit-rotate(180deg);
        .navbar-toggler-icon {
          &:nth-of-type(1) {
            transform: rotate(45deg) translate(5px, 5px);
            -webkit-transform: -webkit-rotate(45deg) -webkit-translate(5px, 5px);
          }
          &:nth-of-type(2) {
            opacity: 0;
          }
          &:nth-of-type(3) {
            transform: rotate(-45deg) translate(5px, -5px);
            -webkit-transform: -webkit-rotate(-45deg) -webkit-translate(5px, -5px);
          }
        }
      }
    }
    @include mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0px;

      transition: 0.3s transform ease-out;
      -webkit-transition: -webkit-transform 0.3s ease-out;

      &:focus {
        box-shadow: none;
      }

      .navbar-toggler-icon {
        transition: 0.3s transform ease-out;
        -webkit-transition: 0.3s -webkit-transform ease-out;
      }

      &.show {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        .navbar-toggler-icon {
          &:nth-of-type(1) {
            transform: rotate(45deg) translate(5px, 5px);
            -webkit-transform: -webkit-rotate(45deg) -webkit-translate(5px, 5px);
          }
          &:nth-of-type(2) {
            opacity: 0;
          }
          &:nth-of-type(3) {
            transform: rotate(-45deg) translate(5px, -5px);
            -webkit-transform: -webkit-rotate(-45deg) -webkit-translate(5px, -5px);
          }
        }
      }
    }

    .navbar-toggler-icon {
      @include tablet {
        height: 2px;
        background: var(--text-white);
        width: 16px;

        & + .navbar-toggler-icon {
          margin-top: 5px;
        }
      }
      @include mobile {
        height: 2px;
        background: var(--text-white);
        width: 16px;

        & + .navbar-toggler-icon {
          margin-top: 5px;
        }
      }
    }
  }

  .navbar-collapse {
    justify-content: center;

    @include tablet {
      position: fixed;
      left: 0;
      top: 0;
      padding: 72px 30px 0px;
      background: var(--dark-color);
      width: 100%;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      z-index: -1;
      transition: 0.3s transform ease-in-out;
      transform: translate(0, -100%);
    }
    @include mobile {
      position: fixed;
      left: 0;
      top: 0;
      padding-top: 72px;
      background: var(--dark-color);
      width: 100%;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      z-index: -1;
      transition: 0.3s transform ease-in-out;
      -webkit-transition: 0.3s -webkit-transform ease-in-out;
      transform: translate(0, -100%);
      -webkit-transform: -webkit-translate(0, -100%);
    }

    &.show {
      transform: translate(0, 0);
      -webkit-transform: -webkit-translate(0, 0);
    }

    .navbar-nav {
      @include tablet {
        height: 100%;
      }
      @include mobile {
        height: 100%;
      }
    }
  }

  .btns-wrapper {
    .wallet {
      right: 187px;
      @include mobile {
        position: static !important;
        right: 0;
        margin-right: 10px;
      }
    }
  }

  .menu-link {
    width: 100%;
    padding: 21px 0;
    & + .menu-link {
      margin-left: 20px;
    }
    @include mobile {
      padding: 18px;
    }
  }

  .header-link {
    transition: 0.3s all ease-in-out;
    position: relative;
    margin-left: 45px;
    color: var(--medium-text-grey);

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      width: 100%;
      background: transparent;
    }

    &:hover {
      color: var(--text-white);
    }

    @include mobile {
      min-height: auto;
      margin: 0;
    }

    .menu-icon {
      filter: brightness(0) invert(1);
      opacity: 0.5;
    }

    &.active {
      color: var(--text-white);
      @include mobile {
        background: #262626;
      }

      &::before {
        background: var(--text-white);
        @include mobile {
          background: transparent;
        }
      }

      .menu-icon {
        filter: none;
        opacity: 1;
      }
    }
  }
  .action-btn {
    align-self: center;
    @include mobile {
      width: 100%;
      margin: 0 !important;
      margin-top: 20px !important;
    }
  }
  .logout-mobile-btn {
    display: none;
    border-radius: 50%;
    padding: 10px 11px;
    background: #323232;
    @include mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      > svg {
        margin-top: -3px;
      }
    }
  }
  .logout-btn {
    padding: 10px;
    > span {
      vertical-align: bottom;
    }

    @include mobile {
      display: none;
    }
  }
  .settings-icon {
    width: 25px;
  }
}

@import "/src/scss/mixins";

.ant-notification-notice-message {
  display: none;
}
.toster-line {
  width: 4px;
  min-height: 70px;
  border-radius: 4px;
}
.toster {
  border-radius: 4px;
  padding: 10px;
}
.toster-container {
  min-height: 70px;
}
.toster-title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.3px;
  max-width: 230px;
  word-wrap: break-word;
  color: #0d1319;
  @include mobile {
    max-width: 180px;
    word-wrap: break-word;
  }
}
.toster-desc {
  font-size: 14px;
  margin-top: 5px;
  font-weight: 500;
  letter-spacing: -0.26px;
  color: #838e9b;
}
.ant-notification {
  // right: 38% !important;
  // @include rwd-cr(768, 1280) {
  //   @media screen and (orientation: landscape) {
  //     right: 30% !important;
  //   }
  //   right: 24% !important;
  // }
  @include mobile {
    right: 0 !important;
  }
}
.ant-notification-notice-close {
  top: 4px !important;
}

@import "../../../scss/mixins";
@import "../../../scss/variables";

.ant-menu-horizontal {
  background: transparent;
  border-bottom: none !important;
  gap: 15px;
  justify-content: flex-start;
  flex: 1;
  &::before {
    display: none;
  }

  @include mobile {
    gap: 1px;
  }
  .ant-menu-item {
    @include mobile {
      padding: 14px 0 !important;
      text-align: center;
      flex: 1 !important;
    }
    padding: 0 0 15px !important;
    &::after {
      display: none !important;
    }
    &:first-child {
      padding-left: 0;
    }

    .ant-menu-title-content {
      color: #000000 !important;

      > p {
        opacity: 0.5 !important;
        @include tablet {
          font-size: 22px !important;
        }

        @include mobile {
          font-size: 16px !important;
        }
      }
    }
  }

  .ant-menu-item-selected {
    opacity: 1;
    border-bottom: 3px solid black !important;

    .ant-menu-title-content {
      > p {
        opacity: 1 !important;
      }
    }
  }
}

@import "../../../scss/variables";
@import "../../../scss/mixins";

// ------------ position details scss ------------
.position-detail-conatiner {
  height: calc(100vh - 70px);
  position: relative;
  display: flex;
  max-width: 1440px !important;
  margin: auto !important;

  .container {
    margin: 0 !important;
    padding: 0 !important;
  }

  .seperator {
    height: 50px;
    width: 1px;
    background-color: #ebd8c1;
  }

  @include rwd(1280) {
    display: block !important;
    max-width: 100vw !important;
  }

  @include rwd-cr(768, 1280) {
    height: calc(100vh - 80px);
    overflow: auto;
  }

  @include tablet {
    display: block;
  }
  @include mobile {
    display: block;
    margin: auto;
    overflow: auto;
    // height: fit-content;

    .stats {
      justify-content: space-between;
      flex-wrap: nowrap !important;
      gap: 12px;
      .stats-with-icon {
        padding: 0;
        h1 {
          font-size: 28px !important;
          margin: 0 !important;
        }
        p {
          font-weight: 600;
          width: min-content;
          color: #7e7e7e !important;
          margin-top: 4px;
        }
      }
    }
  }

  .left-portion {
    background: #ffffff;
    box-shadow: 10px 9px 25px rgba(134, 76, 5, 0.1);
    position: fixed;
    top: 72px;
    left: 0;
    height: 100%;
    width: calc((100vw - 1440px) / 2 + 380px);
    @include mobile {
      position: static;
      width: 100%;
      height: fit-content;
      box-shadow: none;
      .back-wrapper {
        display: none !important;
      }
    }
    @include rwd(1280) {
      position: static;
      width: 100%;
      height: fit-content;
      box-shadow: none;
      .back-wrapper {
        display: none !important;
      }
    }
    > .content-wrapper {
      max-width: 380px;
      margin: 0;
      margin-left: auto;
      padding: 50px 70px;
      height: 95vh;
      overflow-y: auto;

      @include rwd(1580) {
        padding-left: 70px !important;
      }

      @include rwd(1280) {
        position: static;
        max-width: none !important;
        height: unset;
      }

      @include tablet {
        min-height: min-content;
        margin-bottom: 30px;
      }

      @include rwd-cr(768, 1280) {
        padding-top: 0px;
      }
      @include mobile {
        min-height: min-content;
        padding: 0 18px 18px !important;
        margin: 0;
        border: 1px solid #eee3d6;
      }
      .position-stats-card {
        margin: 0 !important;
      }
    }
  }

  .right-portion {
    padding: 0 70px 70px 70px;
    width: 100%;
    height: 100%;
    overflow: auto;
    margin-left: 380px;
    background: var(--main-container-bg);

    @include rwd(1280) {
      margin-left: 0;
    }

    @include rwd-cr(768, 1280) {
      margin-left: unset !important;
      overflow: unset;
      padding: 70px;
    }

    @include tablet {
      padding: 30px;
    }

    @include mobile {
      padding: 18px 18px 80px;
      overflow: unset;
      height: fit-content;
    }

    .filters-wrapper {
      display: none;
      &.active {
        display: block !important;
      }
    }
    .empty-state {
      height: calc(100vh - 72px);
      @include mobile {
        padding: 40px;
        background: #f7f1ea66;
        border-radius: 50%;
        height: fit-content;
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .page-navigation-box {
      @include tablet {
        padding: 0 !important;
      }

      @include mobile {
        flex-direction: column;
        align-items: flex-start;
        padding: 0 !important;
        .count {
          font-size: 22px !important;
          line-height: 26px;
          letter-spacing: -1px;
        }
      }

      .second-half {
        text-align: right;

        @include mobile {
          position: fixed;
          right: 10px;
          bottom: 20px;
          z-index: 2;
          > button {
            width: 54px;
            height: 54px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px !important;
            svg {
              margin: 0;
              width: 22px;
              height: 22px;
            }
            span {
              display: none;
            }
          }
        }
      }
    }
    .top-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include tablet {
        // flex-direction: column;
        align-items: flex-start;
      }
      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      }

      .first-half,
      .second-half {
        flex: 1;
        width: 100%;
      }
    }

    .btn-box {
      svg {
        vertical-align: baseline;
        margin-right: 8px;
      }
    }

    .filter-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include tablet {
        flex-direction: row;
        align-items: end;
        margin-top: 0 !important;
      }
      .first-half {
        .search-input-container {
          &:last-of-type {
            margin-left: 10px;

            @include mobile {
              margin-left: 0px;
              margin-top: 10px;
            }
          }
        }
        flex: 2;

        @include tablet {
          flex: 2;
        }

        @include mobile {
          width: 100%;
          align-items: flex-start;
          flex-direction: column;
        }
      }

      .second-half {
        flex: 1;
        // width: 100%;
        width: fit-content;
        margin-left: 20px;

        @include tablet {
          margin: 20px 0 0 20px;
        }
        @include mobile {
          width: fit-content;
          flex: unset;
        }

        .item-box {
          grid-column-gap: 20px;
        }

        .item-label {
          @include mobile {
            min-width: 80px;
          }
        }

        .field-container {
          width: 100%;
        }
      }
    }
  }
}

.right-portion {
  .listing {
    @include mobile {
      border: none;
    }
    .table-header {
      @include mobile {
        display: none;
      }
    }
    .table-rows {
      .user-details {
        min-width: 0;
        .user-dp {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 50%;
        }
        .user-initials {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: var(--bg-grey);
        }
        .user-name,
        .user-email {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .user-email-contact,
        .user-phone-contact {
          @extend .user-email;
          width: 98%;
          // position: relative;
          // top: 10px;
        }
        .user-key {
          @include mobile {
            margin-bottom: 8px !important;
          }
        }
      }
      @include mobile {
        grid-template-columns: 1fr !important;
        & + .table-rows {
          margin-top: 18px;
        }
        .user-name {
          margin-bottom: 1px !important;
          font-size: 18px !important;
        }
        .user-email {
          margin-bottom: 2px;
          font-size: 16px !important;
        }
        .user-email-contact,
        .user-phone-contact {
          position: relative;
          top: 0px !important;
          margin-bottom: 2px;
          font-size: 12px !important;
        }
        .created {
          color: #41415d !important;
          opacity: 0.7;
        }
        .report-generator {
          display: flex !important;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 18px;
          .status {
            &:not(.completed) {
              font-weight: 600;
              font-size: 13px !important;
              line-height: 16px;
              color: #2e1500;
              border-radius: 77px !important;
              padding: 8px 12px;
              background: #f5f3f2;
            }
          }
        }
      }
      .mobile {
        @include mobile {
          &-order {
            &-1 {
              order: 1;
            }
            &-2 {
              order: 2;
            }
            &-3 {
              order: 3;
            }
          }
          &-flex-row {
            flex-direction: row !important;
          }
          &-hide {
            display: none !important;
          }
        }
        &-visible {
          display: none !important;
          @include mobile {
            display: unset !important;
          }
        }
      }
    }
  }
}

.overall-recommendation {
  display: flex;
  align-items: flex-start;
  gap: 2px;
  position: relative;
  // margin-bottom: 50px;
  .triangle {
    position: absolute;
    bottom: 120%;
    left: 0%;
    transform: translateX(-50%);
    border: 6px solid;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }
  .scale {
    flex: 1;
    &:nth-child(2) {
      & > .color-block {
        border-radius: 4px 0 0 4px;
      }
    }
    &:last-child {
      & > .color-block {
        border-radius: 0 4px 4px 0;
      }
    }
    & > .color-block {
      height: 18px;
    }
    > h4 {
      font-weight: 400;
      color: #7d7d7d;
      text-transform: uppercase;
      font-size: 9px;
      margin-top: 5px;
    }
  }
}

// ------------ mydna details scss ------------

@import "../../../scss/variables";
@import "../../../scss/mixins";

.mydna-detail-container {
  height: calc(100vh - 70px);
  position: relative;
  display: flex;

  @include desktop-lg {
    max-width: 1440px;
    margin: auto !important;
  }

  @include rwd(1280) {
    display: block !important;
  }

  @include rwd-cr(768, 1280) {
    height: calc(100vh - 80px);
    overflow: auto;
  }

  @include tablet {
    display: block;
  }
  @include mobile {
    flex-direction: column;
    height: fit-content;

    .dna-stats-component {
      justify-content: space-between;
      .stat {
        h1 {
          font-size: 28px !important;
        }
        p {
          font-weight: 600;
          font-size: 14px !important;
          line-height: 17px;
          width: min-content;
          color: #7e7e7e !important;
          margin-top: 4px;
        }
      }
    }
  }

  .left-portion {
    background: #ffffff;
    box-shadow: 10px 9px 25px rgba(134, 76, 5, 0.1);
    position: fixed;
    top: 72px;
    left: 0;
    height: 100%;
    width: calc((100vw - 1440px) / 2 + 380px);

    @include rwd-cr(768, 1280) {
      top: 0px;
    }

    @include rwd(1280) {
      position: relative;
      width: 100%;
      height: fit-content;
      box-shadow: none;
      .back-wrapper {
        display: none !important;
      }
    }

    @include mobile {
      position: static;
      width: 100%;
      height: fit-content;
      box-shadow: none;
      .back-wrapper {
        display: none !important;
      }
    }
    > .content-wrapper {
      max-width: 380px;
      margin-left: auto;
      padding: 50px 70px;
      height: 95vh;
      overflow-y: auto;

      @include rwd-cr(768, 1280) {
        margin-left: unset !important;
        height: unset;
        max-width: unset;
      }

      @include tablet {
        min-height: min-content;
        margin-bottom: 30px;
      }
      @include mobile {
        min-height: min-content;
        padding: 0 18px 18px !important;
        margin: 0;
        border: 1px solid #eee3d6;
      }
    }

    .position-stats-card {
      margin: 0 !important;

      @include mobile {
        margin: 0 !important;
      }
    }
  }

  .menu-tab {
    border-bottom: 1px solid #ebd8c1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mobile {
      padding: 0 18px;
      background: #fff;
      box-shadow: 0px 4px 10px 0px #0002;
      .legends {
        display: none;
      }
    }

    & > .switch-tab {
      align-self: flex-end;
      margin-top: 14px;
    }
  }
  .legends {
    display: flex;
    align-items: center;
    gap: 5px;
    img {
      width: 16px;
    }
    span {
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: -0.03em;
      color: #1c1c1c;
    }
  }

  .right-portion {
    padding: 36px 70px 70px 70px;
    margin-left: 380px;
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;

    // @include rwd(1280) {
    //   margin-left: 0;
    // }

    @include rwd-cr(768, 1280) {
      padding: 12px 70px 70px 70px;
      margin-left: unset !important;
      overflow: unset;
    }

    @include tablet {
      padding: 30px;
    }

    @include mobile {
      padding: 0 0 80px;
      height: unset;
      overflow: unset;
      margin: 0;

      .listing {
        padding: 18px;
      }
    }

    .page-navigation-box {
      @include tablet {
        padding: 0 !important;
      }

      @include mobile {
        padding: 0 !important;
      }
    }

    .btn-responsive {
      @include mobile {
        position: fixed;
        right: 10px;
        bottom: 20px;
        z-index: 2;
        > button {
          width: 54px;
          height: 54px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px !important;
          svg {
            margin: 0;
            width: 22px;
            height: 22px;
          }
          span {
            display: none;
          }
        }
      }
    }
  }

  .filter-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    // @include tablet {
    //   flex-direction: column;
    //   align-items: flex-start;
    // }
    @include mobile {
      width: 100%;
      padding: 18px 18px 0;
      margin: 0 !important;
    }

    .first-half {
      flex: 2;

      @include tablet {
        width: 100%;
      }

      @include mobile {
        width: 100%;
      }
    }

    .second-half {
      margin-left: 20px;
      flex: 1;

      @include tablet {
        width: 100%;
        // margin: 20px 0 0 0;
      }
      @include mobile {
        margin-left: 20px;
        width: fit-content;
        flex: unset;
      }

      .item-box {
        @include mobile {
          display: block;
        }

        .item {
          & + .item {
            @include tablet {
              margin-left: 20px;
            }
            @include mobile {
              margin-top: 20px;
            }
          }
        }
      }

      .item-label {
        @include mobile {
          min-width: 80px;
        }
      }

      .field-container {
        width: 100%;
      }
    }
  }

  .gameplay-count {
    color: #dcbc97;
    vertical-align: text-top;
    margin-left: 8px;
  }
}

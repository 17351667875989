.card {
    display: flex;
    background-color: #fff;
    border: 1px solid #e5e7f1;
    border-radius: 12px;
    margin-bottom: 10px;
    overflow: hidden;

    .imageWrapper {
        flex: 0 0 214px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e4ddef;
    }

    .image {
        border-radius: 6px;
        object-fit: cover;
        height: 142px;
    }

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 30px 20px;
        gap: 10px;

        .title {
            font-size: 20px;
            font-weight: 600;
            color: #000;
            margin-bottom: 10px;
        }

        .traits {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;

            .trait {
                background-color: #f7f8fc;
                border-radius: 6px;
                padding: 6px 10px;
                font-size: 14px;
                color: #000;
                font-weight: 500;
                opacity: 0.8;
            }
        }

        .footer {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: auto;

            .details {
                display: flex;
                gap: 13px;

                .tag, .time, .levels {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    font-size: 12px;
                    font-weight: 600;

                    span {
                        text-transform: uppercase;
                        opacity: 0.8;
                    }
                }

                .tag {
                    background-color: #6564db;
                    border-radius: 8px;
                    padding: 8px 12px;
                    color: #fff;
                }
            }

            .actions {
                display: flex;
                gap: 12px;

                .iconButton {
                    border: 1px solid #0000001a;
                    border-radius: 50%;
                    padding: 10px;
                    background: none;
                    cursor: pointer;

                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
}

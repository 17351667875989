@import "/src/scss/mixins";

.my-dna-traits-tab {
  @include mobile {
    padding: 0 18px;
  }
  section.wrapper {
    padding: 40px 0 0;
    @include mobile {
      padding: 30px 0 0;
    }
    .header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ebd8c1;
      padding-bottom: 14px;
      .heading {
        font-weight: bold;
        font-size: 26px;
        line-height: 31px;
        letter-spacing: -1px;
        color: #1c1c1c;
        flex: 1;
        @include mobile {
          font-size: 20px;
          line-height: 24px;
        }
      }
      div {
        flex: 1.4;
        display: flex;
        align-items: flex;
        gap: 9px;
        opacity: 0.6;
        > * {
          font-weight: 600;
          font-size: 16px;
          line-height: 25px;
          letter-spacing: -0.5px;
          color: #1c1c1c;
        }
      }
    }
  }

  .traits-wrapper {
    .trait {
      display: flex;
      align-items: center;
      gap: 30px;
      border-bottom: 1px solid #ebd8c1;
      padding: 14px 0;
      @include mobile {
        flex-wrap: wrap;
        column-gap: 16px;
        row-gap: 28px;
      }
      & > * {
        transition: 0.1s all ease-in-out;
      }
      .info {
        flex: 1.6;
        @include mobile {
          order: 2;
        }
        .name {
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          letter-spacing: -0.5px;
        }
        .description {
          margin-top: 1px;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: -0.02em;
          color: #1c1c1c;
          opacity: 0.5;
          width: 90%;
          @include mobile {
            width: 95%;
          }
        }
      }
      .stats {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 55px;
      }
      .score {
        font-weight: bold;
        font-size: 34px;
        line-height: 41px;
        margin-right: 25px;
        @include mobile {
          order: 1;
          margin: 0;
          background: #F9F3EC;
          padding: 9px;
          letter-spacing: -2px;
        }
      }
      .trait-fill-block-component  {
        flex: 2.65;
        @include mobile {
          order: 3;
          flex-basis: 100%;
        }
      }
    }
  }
}

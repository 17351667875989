@import "_mixins";
@import "_reset";
@import "_layout";
@import "_variables.scss";
@import "_antd.scss";
@import url("https://assets.unberry.com/Messina+Sansfont/stylesheet.css");

.bold {
  font-weight: bold;
  font-style: normal;
}
.regular {
  font-weight: normal;
  font-style: normal;
}
.semibold {
  font-weight: 600;
  font-style: normal;
}
.thin {
  font-weight: 300;
  font-style: normal;
}
// Inter fonts Usage ends here
html {
  vertical-align: middle;
}
body {
  font-size: 16px;
  font-family: "Messina Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  vertical-align: middle;
  @include mobile {
    overflow-x: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
p,
a,
strong {
  line-height: normal;
  // font-weight: initial;
}
p {
  margin-bottom: unset !important;
}

//  flex classes
.flex {
  display: flex !important;
}

//direction
.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column !important;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-1 {
  flex: 1 1 0%;
}

//wrap
.flex-wrap {
  flex-wrap: wrap;
}

//align-items
.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}
.items-baseline {
  align-items: baseline;
}

// align-self

.self-auto {
  align-self: auto;
}
.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}
.self-stretch {
  align-self: stretch;
}

// justify-content
.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.w-full {
  width: 100%;
}
.w-half {
  width: 50%;
}

// For word-break styles
.break-word {
  word-break: break-word;
}

// widths ------------------

.w-1\/12 {
  width: 8.333333%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-33 {
  width: 33.333333%;
}

.w-50 {
  width: 50%;
}

.w-58 {
  width: 58%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-100 {
  width: 100%;
}

.w-15 {
  width: 15%;
}

.h-full {
  height: 100%;
}

.h-100vh {
  height: 100vh;
}
.fit-content {
  width: fit-content !important;
}
// --------- font-weights ----------
$weights: 100, 200, 300, 400, 500, 600, 700, 900;

@each $weight in $weights {
  .weight-#{$weight} {
    font-weight: $weight;
  }
}

// --------------------
// ---- text--center ------
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.jumbotron {
  font-size: 60px;
}

// text colors
.text-white {
  color: #fff;
}
.text-black {
  color: #000;
}
.bold-black {
  color: var(--dark-color);
}
.theme-color {
  color: var(--primary-color);
}
.text-primary-color {
  color: #6c7a8f;
}
.color-secondary {
  color: var(--dark-color);
}
.placeholder-color {
  color: var(--text-grey);
}
.secondary-color {
  color: var(--secondary-color);
}
.text-grey {
  color: var(--text-grey);
}
.text-darkblue {
  color: var(--dark-blue);
}
.text-red {
  color: var(--error-color);
}
.text-darkred {
  color: #cf3701 !important;
}

.text-delete {
  color: #e25d5d !important;
}

.text-purple {
  color: #3f34d2;
}

.text-orange {
  color: #ed7a0f;
}
.text-darkorange {
  color: #cf391d;
}
.text-mediumgrey {
  color: #7f7f96;
}

.text-archieve {
  color: #494949 !important;
}

.background-orange {
  background-color: #f7a261;
}

.background-purple {
  background-color: #b448db;
}

.background-blue {
  background-color: #603bff;
}

.background-green {
  background-color: #7fff00;
}

.mr-5 {
  margin-right: 5px;
}
.no-shadow {
  box-shadow: none !important;
}

// text transform
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}

//disbaled
.disabled {
  pointer-events: none;
  cursor: not-allowed;
}
.pointer-all {
  pointer-events: all;
}

// position
.relative {
  position: relative;
}
.relative-imp {
  position: relative !important;
}
.absolute {
  position: absolute;
}

@for $i from -100 through 100 {
  .left-\[#{$i}\] {
    left: 1px * $i !important;
  }
}

@for $i from -100 through 100 {
  .right-\[#{$i}\] {
    right: 1px * $i !important;
  }
}

@for $i from -100 through 100 {
  .bottom-\[#{$i}\] {
    bottom: 1px * $i !important;
  }
}

@for $i from -100 through 100 {
  .top-\[#{$i}\] {
    top: 1px * $i !important;
  }
}

// line height
.line-1 {
  line-height: 1;
}
.line-normal {
  line-height: normal;
}
.line-45 {
  line-height: 1.45;
}
.line-2 {
  line-height: 2;
}
.line-56 {
  line-height: 1.56;
}
.line-22 {
  line-height: 1.22;
}
.line-17 {
  line-height: 17px;
}
.line-21 {
  line-height: 21px;
}
.line-28 {
  line-height: 28px;
}
.line-32 {
  line-height: 32px;
}

.line-42 {
  line-height: 42px;
}

.italic {
  font-style: italic;
}

.outline-none {
  outline: none;
}
.text-end {
  text-align: end;
}
.no-scroll {
  overflow: hidden !important;
}

.scroll-x {
  overflow-x: auto;
}

.hidden {
  visibility: hidden;
}

.fixed {
  position: fixed;
}
.top-0 {
  top: 0;
}
.bottom-0 {
  bottom: 0;
}
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}

// opacity
.opacity-0 {
  opacity: 0;
}
.opacity-1 {
  opacity: 0.1;
}
.opacity-2 {
  opacity: 0.2;
}
.opacity-3 {
  opacity: 0.3;
}
.opacity-4 {
  opacity: 0.4;
}
.opacity-5 {
  opacity: 0.5;
}
.opacity-6 {
  opacity: 0.6;
}
.opacity-7 {
  opacity: 0.7;
}
.opacity-8 {
  opacity: 0.8;
}
.opacity-9 {
  opacity: 0.9;
}

.cursor-pointer {
  cursor: pointer;
}
button {
  cursor: pointer;
}

a:-webkit-any-link {
  color: unset;
  text-decoration: none;
}
.rotate-180 {
  transform: rotate(180deg);
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.underline {
  text-decoration: underline;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb--2 {
  margin-bottom: -2px;
}

// generates text-12 class for font-sizes
@for $i from 10 through 50 {
  .text-#{$i} {
    font-size: 1px * $i !important;
  }
}

//padding
@for $i from 0 through 30 {
  .p-#{$i*2} {
    padding: 2px * $i !important;
  }
}

//padding-bottom
@for $i from 0 through 30 {
  .pb-#{$i*2} {
    padding-bottom: 2px * $i !important;
  }
}

//padding-top
@for $i from 0 through 30 {
  .pt-#{$i*2} {
    padding-top: 2px * $i !important;
  }
}

//padding-left
@for $i from 0 through 30 {
  .pl-#{$i*2} {
    padding-left: 2px * $i !important;
  }
}

//padding-right
@for $i from 0 through 30 {
  .pr-#{$i*2} {
    padding-right: 2px * $i !important;
  }
}

//padding-vertical
@for $i from 0 through 30 {
  .py-#{$i*2} {
    padding-top: 2px * $i !important;
    padding-bottom: 2px * $i !important;
  }
}
//padding-horizontal
@for $i from 0 through 100 {
  .px-#{$i*2} {
    padding-right: 2px * $i !important;
    padding-left: 2px * $i !important;
  }
}

//margin-vertical
@for $i from 0 through 30 {
  .my-#{$i*2} {
    margin-top: 2px * $i !important;
    margin-bottom: 2px * $i !important;
  }
}
//margin-horizontal
@for $i from 0 through 100 {
  .mx-#{$i*2} {
    margin-right: 2px * $i !important;
    margin-left: 2px * $i !important;
  }
}

// margin-top
@for $i from 0 through 100 {
  .mt-#{$i*2} {
    margin-top: 2px * $i !important;
  }
}

// margin-bottom
@for $i from 0 through 100 {
  .mb-#{$i*2} {
    margin-bottom: 2px * $i !important;
  }
}

//margin-left
@for $i from 0 through 100 {
  .ml-#{$i*2} {
    margin-left: 2px * $i !important;
  }
}

// margin-right
@for $i from 0 through 100 {
  .mr-#{$i*2} {
    margin-right: 2px * $i !important;
  }
}
// margin-top & margin-bottom
@for $i from 0 through 100 {
  .my-#{$i*2} {
    margin-top: 2px * $i !important;
    margin-bottom: 2px * $i !important;
  }
}
// margin-left & margin-right
@for $i from 0 through 100 {
  .mx-#{$i*2} {
    margin-right: 2px * $i !important;
    margin-left: 2px * $i !important;
  }
}

// border
@for $i from 0 through 20 {
  .b-#{$i*2} {
    border-radius: 2px * $i !important;
  }
}

// border-top-left
@for $i from 0 through 20 {
  .btl-#{$i*2} {
    border-top-left-radius: 2px * $i !important;
  }
}

// border-top-right
@for $i from 0 through 20 {
  .btr-#{$i*2} {
    border-top-right-radius: 2px * $i !important;
  }
}

// border-bottom-left
@for $i from 0 through 20 {
  .btr-#{$i*2} {
    border-bottom-left-radius: 2px * $i !important;
  }
}

// border-bottom-right
@for $i from 0 through 20 {
  .btr-#{$i*2} {
    border-bottom-right-radius: 2px * $i !important;
  }
}

// width
@for $i from 0 through 400 {
  .width-#{$i*2} {
    width: 2px * $i !important;
  }
}

// margin-right
@for $i from 0 through 100 {
  .cg-#{$i*2} {
    column-gap: 2px * $i !important;
  }
  .rg-#{$i*2} {
    row-gap: 2px * $i !important;
  }
}

::-webkit-scrollbar {
  display: none;
  background-color: rgba(0, 0, 0, 0);
  // background-color: gray;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(51, 155, 255, 0.3);
  // background-color: #333333;
  border-radius: 20px;
}

::-moz-selection {
  /* Code for Firefox */
  background-color: #f8c2b9;
}

::selection {
  background-color: #f8c2b9;
}
.no-margin {
  margin: 15px 0 !important;
  // overwriting  this for HotelsCreateForm.js line no. 172
}
// .ant-input {
//   font-family: "IBMPlexSans-Medium";
//   color: #2e1500 !important;
//   padding-top: 25px !important;
//   border: none;
//   border-radius: 8px !important;

//   &:placeholder {
//     font-family: "Inter-Medium";
//   }
//   &:placeholder-shown {
//     padding: 12px;
//   }
// }

.ant-picker-dropdown
  .ant-picker-dropdown-range
  .ant-picker-dropdown-placement-bottomLeft {
  top: 374px !important;
}

.ant-modal-wrap {
  backdrop-filter: blur(10px);
}

// using this class for table color in configuration section
.occupant-table {
  color: #050421;
  font-size: 16px;
}

.blank-block-text {
  width: 589px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.18px;
  text-align: center;
  color: #7f8285;
  margin: 13px 0 34px 0;
}
.blank-block-image {
  margin-bottom: 55px;
}
.hide {
  display: none;
}
.autocomplete-dropdown-container {
  border: 1px solid rgb(206, 213, 219);
  border-radius: 5px;
  margin-top: 10px;
}
.ant-upload ~ span {
  display: flex;
  flex-direction: column-reverse;
}
.rc-time-picker-input {
  font-size: 14px !important;
  padding: 11px !important;
  padding-top: 25px !important;
}

.button-dashed:hover {
  background-color: rgba(135, 149, 162, 0.06);
}
.button-dashed {
  border: dashed 2px rgba(135, 149, 162, 0.4) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.36;
  letter-spacing: normal;
  color: #333333;
  text-transform: uppercase;
}
.contact-card {
  margin-bottom: 10px;
  padding: 0 25px;
  height: 80px;
  align-items: center;
  border-radius: 10px;
  // border: solid 1px #f0f3fa;
  border: solid 1px rgba(135, 149, 162, 0.4) !important;
  background-color: #ffffff;
}
.blue-icon {
  color: #3362fa !important;
  font-size: 15px;
}
.black {
  color: #333333 !important;
}

.btn-save-primary {
  width: unset !important;
  padding: 0 20px !important;
}
// .hide-it{
//   display: none !important;
// }
// .show-either{
//   display: block !important;
// }

.ground-rules-bar {
  background-color: #000000;
  color: yellow;
  text-align: center;
  font-size: 12px;
  padding: 2px 0;
}
.ground-rules-bar a:hover {
  color: #ffffff;
}

.position-sticky-top {
  position: sticky;
  top: 0;
  z-index: 1;
}

.line-block {
  display: inline-block;
}

@for $i from 4 through 8 {
  .radius-#{$i} {
    border-radius: $i + "px" !important;
  }
}

.button-dark-rounded {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333333 !important;
  color: #ffffff !important;
  border-radius: 13px !important;
  height: 27px !important;
  padding: 6px 20px !important;
}

.mt-15 {
  margin-top: 15px;
}

.color-blue {
  color: #0077ea !important;
}
.field-container {
  .ant-select-dropdown {
    border: 1px dotted #333333 !important;
  }
  .ant-select-selector {
    border: 2px solid black;
    display: flex;
    color: red !important;
  }
}

.vertical-scroll-box {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  padding: 0px 10px;
}

// --------------
// grid
.grid {
  display: grid;
}
.grid-cols-none {
  grid-template-columns: none;
}
.grid-cols-auto {
  grid-template-columns: auto;
}
.grid-rows-none {
  grid-template-rows: none;
}
.grid-rows-auto {
  grid-template-rows: auto;
}
@for $i from 1 through 25 {
  .grid-gap-#{$i} {
    grid-gap: 1px * $i !important;
  }
}
// grid-cols
@for $i from 1 through 12 {
  .grid-cols-#{$i} {
    grid-template-columns: repeat($i, minmax(0, 1fr)) !important;
  }
}

// grid-rows
@for $i from 1 through 12 {
  .grid-rows-#{$i} {
    grid-template-rows: repeat($i, minmax(0, 1fr)) !important;
  }
}
.card-light-border {
  padding: 15px 26px 16px 24px;
  border-radius: 4px;
  border: solid 2px #f0f3fa;
}
.ant-drawer-body {
  padding: 0px !important;
}

// Tag: create tag from any div
.tag-box {
  background-color: rgba(196, 196, 196, 0.2);
  border-radius: 20px;
  padding: 0px 20px;
  color: rgba(255, 255, 255, 0.7);
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.dark {
    background: var(--dark-color);
    color: var(--text-white);
  }
  &.tag-lg {
    height: 36px;
  }
}

.tab-wrapper {
  border-bottom: 1px solid #ebd8c1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @include mobile {
    margin: 0 !important;
    position: sticky;
    z-index: 1;
    top: 0;
    width: 100%;
    background: #fff;
    padding: 0 18px;
    box-shadow: 0px 4px 10px 0px #0002;
  }
  .gameplay-count {
    color: var(--light-brown);
  }

  .btn-box {
    margin-bottom: 10px;
    @include mobile {
      position: fixed;
      right: 10px;
      bottom: 20px;
      margin-bottom: 0px;
      z-index: 2;
    }
    .ant-btn {
      height: fit-content !important;
      padding: 10px 20px !important;
      display: flex;
      align-items: center;
      gap: 4px;
      @include mobile {
        width: 54px !important;
        height: 54px !important;
        border-radius: 50% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px !important;
        svg {
          margin: 0;
          width: 22px;
          height: 22px;
        }
        span {
          display: none;
        }
      }
    }
  }
}

.screen-only {
  background-color: #1c1c1c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 100vh;
  width: 100vw;
  img {
    width: 50%;
  }
}

.screen-only-message {
  font-size: 3em;
  margin-top: 20px;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding: 20px 20px;
  -webkit-backdrop-filter: blur(18.4px);
  backdrop-filter: blur(18.4px);
  box-shadow: 0 9px 24px 0 rgba(0, 0, 0, 0.18);
  background-color: rgba(100, 96, 96, 0.33);
  img {
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }
}

@media (min-width: 320px) {
  .screen-only-message {
    font-size: 0.8em;
  }
}

@media (min-width: 375px) {
  .screen-only-message {
    font-size: 1em;
  }
}

@media (min-width: 414px) {
  .screen-only-message {
    font-size: 1.2em;
  }
}

.custom_fc_frame {
  @include mobile {
    left: 10px !important;
    bottom: 15px !important;
    z-index: 200 !important;
  }
}

.dropdown-menu-overlay {
  @include mobile {
    top: unset !important;
    // right: unset !important;
    left: 0 !important;
    bottom: 0 !important;
    position: fixed;
    width: 100%;
    transition: 5s all ease !important;

    .ant-dropdown-menu {
      padding-bottom: 40px;
      > li {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        padding: 18px;
      }
    }
  }
}
.text-clip {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.hoverEffect:hover {
  box-shadow: 0px 6px 34px -10px rgba(195, 195, 195, 0.75);
  transition: box-shadow 500ms;
}
.drawers {
  .ant-drawer-content-wrapper {
    width: 35% !important;
    @include mobile {
      width: 100% !important;
    }
  }
}
.underline-on-hover:hover {
  text-decoration: underline;
  cursor: pointer;
}

.break-word {
  word-wrap: break-word;
}

.width-80vw {
  width: 80vw;
}

.color-grey {
  color: var(--text-grey);
}

@for $i from -10 through 20 {
  .letter-spacing-#{$i} {
    letter-spacing: 0.1px * $i !important;
  }
}

.select-none {
  user-select: none;
}
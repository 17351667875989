@import "../../../scss/_variables.scss";

.position-analytics {
  max-width: 1440px;
  margin: auto;
  .goback-icon {
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 50%;
  }
  .bottom-border-white {
    background: #fff;
    width: 100%;
    height: 6px;
    bottom: -4px;
    left: 0px;
  }
  .tab-label {
    height: 52px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s ease-in-out, width 0.4s ease-in;
  }
  .tab-container {
    background: #fff;
    padding: 7px;
    border-bottom: none !important;
    transition: background 0.2s ease-in-out;
  }
  .tabs-container {
    gap: 10px;
    z-index: 1;
  }
  .active-border {
    border: 1px solid #f3ddc2;
  }
  .inactive-border {
    border: 1px solid #eee3d6;
  }
  .active-tab-label {
    background: #1c1c1c;
    color: #fff;
    width: 178px;
  }
  .inactive-tab-label {
    background: #fff;
    color: #1c1c1c;
    width: 184px;
  }
  .topleft-radius-20 {
    border-top-left-radius: 20px;
  }
  .topright-radius-20 {
    border-top-right-radius: 20px;
  }
  .topleft-radius-30 {
    border-top-left-radius: 30px;
  }
  .topright-radius-30 {
    border-top-right-radius: 30px;
  }
  .bottomleft-radius-20 {
    border-bottom-left-radius: 20px;
  }
  .bottomright-radius-20 {
    border-bottom-right-radius: 20px;
  }
  .bottom--4 {
    bottom: -4px;
  }
  .right--19 {
    right: -19px;
  }
  .right--18 {
    right: -18.5px;
  }
  .left--19 {
    left: -19px;
  }
  .left--18 {
    left: -18.5px;
  }
  .tab-panel-container {
    background: #fff;
  }
  .stats-cards-container {
    gap: 4px;
    width: 100%;
    overflow-x: auto;
    .stats-card {
      &-scoredist {
        width: fit-content;
      }
    }
  }
  .comparative-btn {
    background: #fff;
    border: 1px solid #eee3d6;
    outline: none;
    border-radius: 30px;
  }
}

.comparative-drawer {
  .ant-drawer-content-wrapper {
    .zone-pill {
      background: #fff;
      border: 1px solid #eee3d6;
      border-radius: 30px;
      &-active {
        background: #ff864e;
        border: 1px solid #ff864e;
        color: #fff;
        border-radius: 30px;
      }
    }
    .selected-zones-container {
      gap: 10px;
      min-width: 90%;
      width: 100%;
      flex-wrap: wrap;
    }
    .analysis-step1-container {
      height: 90vh;
      .zones-container {
        top: 35%;
        left: 50%;
        transform: translateX(-50%);
        .zones-pills-container {
          width: 45vw;
          gap: 10px;
          flex-wrap: wrap;
        }
      }
      .compare-submit-btn {
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
        background: #1c1c1c;
        outline: none;
        border: none;
        color: #fff;
        border-radius: 30px;
      }
    }
    .analysis-step2-container {
      .graph-container {
        border: 1px solid #f3ddc2;
        .graph-heading {
          letter-spacing: 0.3px;
        }
        .legend-text > span {
          display: inline-block;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          margin-right: 6px;
        }
        .legend-text:nth-child(1) > span {
          background: #71df7c;
        }
        .legend-text:nth-child(2) > span {
          background: #a0f383;
        }
        .legend-text:nth-child(3) > span {
          background: #ffe37e;
        }
        .legend-text:nth-child(4) > span {
          background: #fe9b9b;
        }
      }
      .stats-card {
        .scoredist-type-tag {
          color: #fff;
          background: #363636;
          border-radius: 6px;
          padding: 8px;
          letter-spacing: 0.3px;
        }
      }
      .compare-goback-btn {
        background: #fff;
        outline: none;
        border: 1px solid #1c1c1c;
        color: #1c1c1c;
        border-radius: 30px;
      }
    }
  }
}

.position-analytics,
.analysis-step2-container {
  .top-2 {
    top: 2px;
  }
  .stats-card {
    background: #fff;
    border: 1px solid #f3ddc2;
    p {
      white-space: nowrap;
    }
    .stats-card-heading {
      letter-spacing: 0.5px;
      line-height: 16px;
    }
    &-poolperf {
      width: fit-content;
    }
  }
}

.questions-drawer {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: unset;
    padding-top: unset !important;
    border: 1px solid #eee3d6 !important;
    border-radius: 16px;
    background: transparent !important;
  }
  .ant-select {
    display: flex;
    align-items: center;
  }
  .ant-select-selection-item {
    padding-top: 6px !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ef673e;
  }
  .ant-tabs-tab:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-remove:focus,
  .ant-tabs-tab-btn:active {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-tabs-ink-bar {
    background: #ef673e;
  }
  .loading-container {
    width: 900px;
    .ant-spin-dot-item {
      background: #ff864e;
    }
  }
  .reset-filters {
    width: 30px;
    background: var(--avg-dark-color);
    &:hover {
      span > svg {
        transform: scale(1.2) rotate(0.15turn);
        transition: transform 0.2s ease-out;
      }
    }
    span > svg {
      fill: #fff;
    }
  }
  table {
    th,
    td {
      height: 62px;
      padding-left: 10px;
      padding-top: 20px;
      letter-spacing: 0.3px;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: #cacaca;
      &:nth-child(1) {
        border-left-width: 1px;
        border-left-style: solid;
        border-left-color: #cacaca;
      }
      &:last-child {
        border-right-width: 1px;
        border-right-style: solid;
        border-right-color: #cacaca;
      }
    }
    th {
      border-top-width: 1px;
      border-top-style: solid;
      border-top-color: #cacaca;
    }
  }
  .traits-table {
    th,
    td {
      width: 172px;
      padding-bottom: 10px;
    }
    .trait {
      color: #202020;
      &-name {
        background: #fbdfd9;
        border-radius: 12px;
        padding: 4px 8px;
        width: fit-content;
      }
    }
  }
  .questions-table {
    th,
    td {
      width: 150px;
      &:nth-child(1) {
        width: 300px;
        max-width: 300px;
        padding-bottom: 10px;
      }
    }
    .ques {
      color: #202020;
      &-name {
        max-height: 200px;
        overflow-y: auto;
      }
      &-skill {
        background: #fbdfd9;
        border-radius: 12px;
        padding: 4px 8px;
        width: fit-content;
      }
      &-level {
        border-radius: 12px;
        padding: 4px 8px;
        width: fit-content;
        &-easy {
          background: #ffdb8f;
        }
        &-medium {
          background: #f4b4a6;
        }
        &-hard {
          color: #fff;
          background: #ef673e;
        }
      }
    }
  }
}

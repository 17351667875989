@import "../../scss/mixins";
@import "../../scss/variables";

.layout-container {
  height: 100%;
  background: var(--main-container-bg);

  .main-container {
    background: var(--main-container-bg);
    color: var(--dark-color);
    position: relative;

    @include tablet {
      height: auto;
    }
    @include mobile {
      margin-top: 0px;
      padding-top: 0px;
      height: auto;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(
        141.3deg,
        rgba(#505050, 0.25) -2.91%,
        rgba(31, 31, 31, 0) 35.41%
      );
      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        141.3deg,
        rgba(#505050, 0.25) -2.91%,
        rgba(31, 31, 31, 0) 35.41%
      );
      transform: rotate(-180deg);
      z-index: -1;
    }
  }
}

@import "../../scss/variables";
@import "../../scss/mixins";

.all-settings {
  .mobile-tab-content {
    max-width: 90vw;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
    border: unset;
    padding: 8px 100px 8px 12px;
    border-radius: 8px;
  }
  .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active {
    background: black;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
    background: black;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
    background: white;
    display: flex;
    padding: 8px 34px;
  }
  .ant-tabs-tab {
    font-size: 18px;
    &:hover {
      color: unset;
    }
  }
  .ant-tabs-tab-active {
    background: #000;
    border-radius: 4px;
  }
  .ant-tabs-btn:hover {
    color: #000;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff;
    background: #000;
  }

  .organization-logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    @include mobile {
      width: 94px;
      height: 94px;
    }
  }

  .container {
    max-width: 1440px !important;
    padding: 0 20px 0 30px;
    @include mobile {
      padding: 0px;
    }
  }

  .company-block {
    background-color: var(--text-white);
    padding: 20px 0;
    border-bottom: 1px solid #ebd8c1;

    @include tablet {
      p {
        font-size: 22px !important;
      }
    }

    @include mobile {
      padding: 20px 10px;
      p {
        font-size: 18px !important;
      }
    }

    > div {
      max-width: 1440px;
      padding: 0 70px;
      margin: auto;
      @include mobile {
        flex-direction: column !important;
        > div {
          flex-direction: column;
        }
      }
      .company-name {
        @include mobile {
          flex-direction: column !important;
          > p {
            font-weight: bold;
            font-size: 26px !important;
            line-height: 26px;
          }
        }
        .ant-avatar-circle {
          background: var(--dark-color);
          height: 40px;
          width: 40px;
          @include mobile {
            width: 94px;
            height: 94px;
            margin: 0 0 7px !important;
            > span.ant-avatar-string {
              font-size: 50px;
            }
          }
        }
      }
    }

    .credits {
      @include mobile {
        background: #1c1c1c0d;
        // background: #f5f3f2;
        border-radius: 77px;
        padding: 13px 16px;
        margin-top: 10px;
      }
      .balance {
        color: var(--dark-blue);
        line-height: 26px;
      }
      .transaction {
        color: #7d7ea3;
        align-self: center;
        vertical-align: text-top;
        align-self: center;
      }
    }
  }
  .heading-block {
    position: relative;
    bottom: 2px;
    border-bottom: 1px solid #ebd8c1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 18px;
    margin-bottom: 30px;

    @include rwd-cr(768, 1280) {
      min-width: 470px;
    }

    @include mobile {
      border-top: 1px solid #f1e8de;
      padding: 14px 18px;
      margin: 0;
      background: #fff;
      > p {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px !important;
      }
      > button {
        position: fixed;
        right: 10px;
        bottom: 20px;
        z-index: 2;
        width: 54px;
        height: 54px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px !important;
        svg {
          margin: 0 !important;
          width: 22px;
          height: 22px;
        }
        span {
          display: none;
        }
      }
    }

    .gameplay-count {
      color: var(--light-brown);
    }
  }
  .content-wrapper {
    padding: 30px 0;
    max-width: 1440px !important;

    @include mobile {
      padding: 0;
    }

    .ant-btn-primary {
      > svg {
        vertical-align: text-top;
        margin-right: 5px;
      }
    }
  }

  .admins {
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    @include mobile {
      grid-template-columns: 1fr;
      padding: 18px;
    }
  }
  .listing {
    @include mobile {
      border: none;
      min-height: 100vw;
    }
    .table-header {
      @include mobile {
        display: none;
      }
    }
    .table-rows {
      .user-details {
        min-width: 0;
        .user-name,
        .user-email {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .user-email-contact,
        .user-phone-contact {
          @extend .user-email;
          width: 98%;
          position: relative;
          top: 10px;
        }
      }
      @include mobile {
        grid-template-columns: 1fr !important;
        & + .table-rows {
          margin-top: 18px;
        }
        .user-name {
          margin-bottom: 1px !important;
          font-size: 18px !important;
        }
        .user-email {
          margin-bottom: 2px;
          font-size: 16px !important;
        }
        .user-email-contact,
        .user-phone-contact {
          position: relative;
          top: 0px !important;
          margin-bottom: 2px;
          font-size: 12px !important;
        }
        .created {
          color: #41415d !important;
          opacity: 0.7;
        }
        .report-generator {
          display: flex !important;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 18px;
          .status {
            &:not(.completed) {
              font-weight: 600;
              font-size: 13px !important;
              line-height: 16px;
              color: #2e1500;
              border-radius: 77px !important;
              padding: 8px 12px;
              background: #f5f3f2;
            }
          }
        }
      }
      .mobile {
        @include mobile {
          &-order {
            &-1 {
              order: 1;
            }
            &-2 {
              order: 2;
            }
            &-3 {
              order: 3;
            }
          }
          &-flex-row {
            flex-direction: row !important;
          }
          &-hide {
            display: none !important;
          }
        }
        &-visible {
          display: none !important;
          @include mobile {
            display: unset !important;
          }
        }
      }
    }
  }
}

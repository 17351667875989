@import "/src/scss/mixins";

.dna-stats-component {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
  @include mobile {
    gap: 22px;
  }
  &.overall-stats-tablet {
    @include rwd-cr(768, 1280) {
      width: fit-content;
      p {
        white-space: nowrap;
      }
    }
    
  }
  .stat {
    padding: 0;
    margin: 0 !important;
    @include mobile {
      width: fit-content;
    }
    h1 {
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 22px !important;
      line-height: 22px !important;
      margin: 0 !important;
      @include mobile {
        font-weight: 600 !important;
        font-size: 20px !important;
        line-height: 28px !important;
      }
    }
    p {
      margin-top: 4px;
      font-weight: normal;
      font-size: 12.5px !important;
      line-height: 16px;
      opacity: 0.8;
      color: #1c1c1c !important;
      @include mobile {
        font-weight: normal;
        font-size: 12px !important;
        line-height: 16px;
        letter-spacing: -0.01em;
        margin-top: 0;
      }
    }
  }
}

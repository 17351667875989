.scale {
  height: fit-content;
  .scale-unit {
    height: 6px;
    width: 9%;
    background: #e5e5e5;
    margin-right: 1%;
    border-radius: 5px;
    opacity: 0.5;
  }
  .scale-bound {
    height: 12px;
    opacity: 0.75;
    border-radius: 3px;
  }
  .scale-score {
    border-radius: 50%;
    color: white;
    bottom: 100%;
  }
  .scale-median-pointer {
    background: #d2d2d2;
    height: 28px;
    width: 5px;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 40px;
  }
}

@import "/src/scss/mixins";

.dropdown-menu-component {
    > svg {
        width: 24px;
        &:active {
            circle {
                &:first-child {
                    fill: #1c1c1c;
                    opacity: 1;
                }
                &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                    fill: #fff;
                }
            }
        }
        @include mobile {
            width: 32px;
        }
    }
}
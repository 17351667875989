@import "src/scss/variables";
@import "src/scss/mixins";

* {
  --controls-bg: #f2f2fb;
  --controls-color: #5c5c7c;
}

.default-btn {
  background: var(--controls-bg);
  color: var(--controls-color);
  border: none;
  border-radius: 40px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.03em;
  padding: 8px;
  @include mobile {
    font-size: 13px;
    padding: 10px;
  }

  svg {
    width: 28px;
    height: 26px;
  }
}

.loader-container {
  background: var(--bg-grey);
  width: 100%;
  height: 100%;
  @include mobile {
    width: 60vw;
    height: 30vh;
  }
  svg {
    width: 40px;
    height: auto;
  }
}

.loader-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.loader-snapshots-container {
  width: 640px;
  height: 480px;
  > .loader-container {
    background: transparent;
  }
}

.video-container {
  height: 400px;
  @include mobile {
    width: 60vw !important;
    height: 30vh !important;
  }

  .video-player {
    width: 500px;
    height: 400px;
    @include mobile {
      width: 60vw !important;
      height: 30vh !important;
    }
  }
}

.snapshots-modal.media-proctoring-modal {
  min-width: 50vw !important;
  width: 98vw !important;
  .ant-modal-content {
    height: 95vh;
    @include mobile {
      height: 100%;
      max-height: 95vh;
    }
    .ant-modal-body {
      height: 100%;
      .ant-menu-horizontal {
        width: fit-content;
        flex: 0;
        border-bottom: 1px solid var(--bg-grey) !important;
        @include mobile {
          gap: 5px;
        }
        .ant-menu-item {
          &:first-child {
            margin-right: -10px;
          }
          .ant-menu-title-content > p {
            font-size: 16px !important;
            transition: opacity 0.4s ease;
          }
        }
        .ant-tabs-tab-btn {
          font-weight: 600;
          color: #000;
          opacity: 0.5;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: #000;
          opacity: 0.8;
        }
        .ant-tabs-nav {
          max-width: 90vw;
        }
      }
    }
    .grid {
      height: 60vh;
      @include mobile {
        height: 250px;
      }
    }
    .grid.grid-cols-6 {
      @include rwd(1280) {
        grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
      }
      @include rwd(900) {
        grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
      }
      @include rwd(768) {
        grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
      }
      @include rwd(478) {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
      }
    }
    .entire-video-btn {
      position: absolute;
      right: 5%;
      svg {
        position: relative;
        top: 1px;
      }
    }
    .entire-video-btn-shadow {
      box-shadow: 4px 4px 8px rgba(71, 71, 71, 0.6);
      &:hover {
        box-shadow: 4px 5px 10px rgba(71, 71, 71, 0.6);
      }
    }
    .filter-btn {
      transition: all 0.2s ease;
    }
    .controls-container {
      color: var(--controls-color);
      @include mobile {
        flex-direction: column;
        gap: 2px;
      }
      .skip-btn svg {
        width: 20px;
        height: 20px;
        margin-top: 4px;
      }
    }
  }
  .ant-slider {
    width: 80%;
    &-rail {
      background: var(--bg-grey);
    }
    &-handle {
      border-color: var(--controls-color);
      &:focus {
        box-shadow: 0 0 0 4px rgba(242, 242, 251, 0.5);
      }
    }
    &-track {
      background-color: var(--controls-color);
    }
    &:hover .ant-slider-handle:not(.ant-tooltip-open) {
      border-color: var(--controls-color);
    }
  }
  .ant-radio-button-wrapper {
    &:hover {
      color: #000;
    }
    &:first-child {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
    &:last-child {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #000;
    border-color: #000;
    &:hover {
      color: #fff;
    }
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    display: none;
  }
  .ant-checkbox-group-item {
    margin-right: 15px !important;
    > .ant-checkbox + span {
      color: var(--primary-color);
      @include mobile {
        font-size: 14px;
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background: rgb(61, 61, 61);
      border-color: rgb(61, 61, 61);
    }
  }
  .ant-segmented {
    background-color: #fff;
    &-item-selected {
      background-color: var(--controls-bg);
      color: var(--controls-color);
    }
  }
}

page {
  size: A4;
  background: white;
  display: block;
  margin: 0 auto;
  page-break-after: always;
  width: 21cm;
  // width: 21*37.7952755906px;
  // width: 793.70079px; 0.55118110416
  min-height: calc(29.7cm - 0.5cm);
  margin-bottom: 0.5cm;
  padding: 45px 50px;
}
@media print {
  @page {
    margin: 0 !important;
    margin-top: 0cm !important;
    margin-bottom: 0cm !important;
    margin-left: 0cm !important;
    margin-right: 0cm !important;
  }
  body,
  page {
    margin: 0;
    box-shadow: 0;
  }

  .first-report-page {
    background: white;
  }

  page {
    size: A4;
    background: white !important;
    display: block;
    margin: 0 auto;
    page-break-after: always;
    width: 21cm;
    max-height: 29.7cm;
    margin-bottom: 0.5cm;
  }
}
.footer-divider {
  background-color: black;
  height: 1px;
}

.multi-chat-section {
  .chat-card {
    border-radius: 10px;
    background: #f2f2fb;
    min-height: 300px;
  }
  .chat-header {
    .chat-logo {
      background: #fff;
      span {
        color: #e97f40;
        line-height: 15px;
        letter-spacing: -0.28px;
      }
    }
  }
  .chat-title {
    .heading {
      color: #2a2a41;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }
  .cefr-score {
    p {
      line-height: 19px;
      letter-spacing: -0.5px;
    }
    .score-pill {
      width: 44px;
      height: 44px;
      background: #2a2a41;
      line-height: 27px;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }

  .cefr-markers-container {
    column-gap: 62px;
    .cefr-marker {
      gap: 0px;
      .marker {
        letter-spacing: -0.5px;
        &-score {
          line-height: 30px;
          color: #2a2a41;
        }
        &-name {
          line-height: 19.2px;
          color: #2a2a41;
        }
      }
    }
  }

  .chats-list-container {
    gap: 10px;
    .heading {
      color: #2a2a41;
    }
    .chat-info-container {
      background: #fff;
      .title {
        color: #2a2a41;
        line-height: 19px;
      }
      .subtitle {
        color: #2a2a41;
        line-height: 14px;
        letter-spacing: 0.5px;
      }
      .metric-container {
        margin-right: 2.5vw;
        .metric-title {
          color: #2a2a41;
          line-height: 14px;
          letter-spacing: 0.4px;
        }
        .metric-subtitle {
          color: #2a2a41;
          line-height: 21px;
        }
      }
      .chat-score-container {
        p {
          color: #2a2a41;
          white-space: nowrap; 
        }
        .score-pill {
          width: 34px;
          height: 34px;
          background: #2a2a41;
          line-height: 27px;
          letter-spacing: 0px;
          color: #ffffff;
        }
        .view-details-btn {
          background: none;
          border: none;
          outline: none;
          color: #365ce3;
          svg {
            margin-bottom: -2px;
            margin-right: 2px;
          }
        }
      }
    }
  }
}

.multichat-detail-modal {
  .ant-modal-content {
    border-radius: 40px !important;
    width: 90vw;
    height: 94vh;
    overflow-y: hidden;
  }
  .ant-modal-close {
    display: none;
  }
  .outer-container {
    .close-icon {
      position: absolute;
      right: 20px;
      top: 15px;
      font-size: 24px;
    }

  .main-data-container {
    .left-portion {
      width: 65%;
      border-right: 1px solid #d9d9d9;
      padding: 0px 20px 20px 30px;
      .title {
        .heading {
          color: #2a2a41;
        }
      }
      .cefr-score {
        p {
          line-height: 19px;
          letter-spacing: -0.5px;
        }
        .score-pill {
          width: 45px;
          height: 45px;
          background: #2a2a41;
          line-height: 27px;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
	  .left-scroll-section { 
		height: 80vh;
		overflow-y: auto;
		.summary-text {
			color: #2a2a41;
			opacity: 0.7;
			line-height: 18px;
		}
		.ltop-portion,
		.lbottom-portion {
		  display: grid;
		  grid-template-columns: 1fr 1fr;
		  row-gap: 30px;
		  column-gap: 50px;
		  padding: 0 40px 40px 0;
		  border-bottom: 1px solid #d9d9d9;
		}
		.lbottom-portion {
		  padding: 40px 40px 20px 0;
		  border-bottom: unset;
		}
	  }
    }
    
    .right-portion {
      width: 35%;
      padding: 0px 0 20px 30px;
      .title {
        .heading {
          color: #2a2a41;
        }
        .summary-text {
          color: #2a2a41;
          opacity: 0.7;
          line-height: 18px;
        }
      }
    }
    .cefr-marker {
      display: flex;
      column-gap: 20px;
      .marker {
        &-score {
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.5px;
        }
        &-name {
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.5px;
        }
        &-desc {
          color: #a5a5a5;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.5px;
        }
      }
    }
  }
  
  .right-scroll-section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: 65vh;
    overflow-y: auto;
  }
  .chat-message {
    &-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 0.5rem;
      font-size: 16px;
      font-weight: 400;
    }
    &-inner {
      width: fit-content;
      max-width: 70%;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
  }
  .bot-msg {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
    background-color: #f2f2f2;
  }
  .user-msg {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    background-color: #f9e4cb;
  }
}
}

.chat-instructions-modal {
  .ant-modal-content {
    border-radius: 40px !important;
    max-height: 80vh;
    overflow-y: hidden;
    ol {
      list-style: decimal;
      padding-inline-start: 40px; 
    }
    ul {
      list-style: disc;
      padding-inline-start: 40px;
    }
    h2 {
      font-size: 1.5em;
      font-weight: 500;
    }
    h3 {
      font-size: 1.17em;
      font-weight: 500;
    }
    h4 {
      font-size: 1em;
      font-weight: 500;
    }
  }
  .ant-modal-close {
    display: none;
  }
  .close-icon {
    position: absolute;
    right: 20px;
    top: 15px;
    font-size: 24px;
  }
  .instructions {
    height: calc(80vh - 150px);
    overflow-y: auto;
  }
}
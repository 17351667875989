@import "/src/scss/mixins";
@import "/src/scss/variables";

.dna-stats-card {
  width: 100%;
  padding: 22px;
  background: var(--text-white);
  color: #000000 !important;
  border: 1px solid #f3ddc2;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);

  .position-title {
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: -1px;
    color: #1c1c1c;
  }

  &:hover {
    box-shadow: -4px 5px 5px 0px rgba(133, 75, 5, 0.12);
    transform: translateY(-10px);
  }

  .position-icon-box {
    top: -20px;
    width: 73px;
    height: 73px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--light-dark-color);
    border: 4px solid var(--mid-dark-color);
  }

  .cancel-tag {
    border: 1px solid var(--primary-color);
    background: var(--primary-color);
    color: var(--text-white);
    border-radius: 20px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 16px;
    z-index: 50;
  }
  .created {
    bottom: 6px;
  }
  .trait-heading {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.5px;
    color: #1c1c1c;
  }

  .traits-container {
    margin-top: 6px;

    &.empty {
      margin-top: 45px;
      position: relative;

      .info {
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 1;
        transform: translateY(-50%);
        > p {
          margin-top: 5px;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.5px;
          color: #363636;
        }
      }
      .blur-container {
        filter: blur(20px);
      }
    }

    .trait-bar {
      padding: 10px;
      height: fit-content;
      min-height: 34px;
      & + .trait-bar {
        margin-top: 4px;
      }
      .median {
        border-width: 2px;
      }
      .trait-name {
        font-weight: bold;
        font-size: 13px;
        line-height: 13px;
        letter-spacing: -0.5px;
      }
    }
  }

  .edit-name {
    width: 65% !important;
    border: none !important;
    border-bottom: 1px solid #eee3d6 !important;
  }

  .save-edit-options {
    display: flex;
    justify-content: center;
    align-items: center;

    .check-icon {
      cursor: pointer;
      margin-left: 10px;
      background: #F3F3FA;
      border-radius: 100%;
      padding: 6px;
    }

    .close-button {
      cursor: pointer;
      circle {
        transition: fill 0.24s linear;
      }

      &:hover {
        circle {
          transition: fill 0.24s linear;
          fill: #e1e1e6;
        }
      }
    }

    .check-icon {
      transition: background-color 0.24s linear;

      &:hover {
        transition: background-color 0.24s linear;
        background: #e1e1e6;
      }
    }
  }
}

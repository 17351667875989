@import "../../scss/mixins";
@import "../../scss/variables";

.feedbacks-menu-width {
  width: 14vw;
  @include rwd-cr(768, 1280) {
    @media screen and (orientation: landscape) {
      width: 18vw;
    }
    width: 28vw;
  }
  @include mobile {
    width: 50vw;
  }
}

.ant-dropdown-menu-sub {
  max-height: 50vh;
  overflow-y: auto;
  @include mobile {
    max-height: 30vh;
  }
}

.feedbacks-listing {
  max-width: 1440px;
  margin: auto;
  min-height: calc(100vh - 95px);
  @include mobile {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }

  .listing {
    .status {
      justify-content: flex-start;
    }
    @include mobile {
      border: none;
    }
  }

  .table-rows {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    @include mobile {
      grid-template-columns: 1fr !important;
      & + .table-rows {
        margin-top: 18px;
      }
    }
  }

  .search {
    max-width: 40vw;
    @include mobile {
      max-width: 94vw;
      width: 94vw;
      margin: 10px 0px;
    }
  }

  .mobile-filter-icon {
    position: relative;
    left: 80vw;
  }

  .table-header {
    @include mobile {
      display: none;
    }
  }

  .subheading-container {
    @include mobile {
      flex-direction: column;
      align-items: start;
    }

    .ant-dropdown-trigger {
      border-radius: 40px;
      box-sizing: border-box;
      border: 1px solid #eee3d6;
      text-align: center;
      background: var(--text-white) !important;
      font-size: 18px !important;
      font-weight: 600 !important;
      color: #a8a8a8 !important;
      padding: 14px 10px 14px 20px !important;
      height: fit-content !important;
      width: max-content !important;
      @include mobile {
        display: inline-block !important;
        width: 100% !important;
        border-radius: 0;
        border: none !important;
        border-bottom: 1px solid #eee3d6 !important;
      }
    }

    .ant-dropdown {
      @include mobile {
        width: 100% !important;
        border-radius: 0;
        border: none !important;
        border-bottom: 1px solid #eee3d6 !important;
      }
    }
  }

  .subheading {
    @include rwd-cr(768, 1280) {
      @media screen and (orientation: landscape) {
      }
      font-size: 20px !important;
    }
    @include mobile {
      font-size: 20px !important;
    }
  }

  .name-email-container {
    @include rwd-cr(768, 1280) {
      @media screen and (orientation: landscape) {
        flex-direction: row;
        align-items: center;
      }
      flex-direction: column;
      align-items: start;
    }

    .name-initials {
      background: var(--light-grey);
      border-radius: 50%;
      text-transform: uppercase;
    }

    .name {
      max-width: 150px;
      @include rwd-cr(768, 1280) {
        @media screen and (orientation: landscape) {
          max-width: 150px;
        }
        margin-top: 5px;
        max-width: 110px;
      }
    }
  }

  .position {
    max-width: 150px;
    @include rwd-cr(768, 1280) {
      @media screen and (orientation: landscape) {
        max-width: 150px;
      }
      max-width: 80px;
    }
    @include mobile {
      margin-top: 20px;
    }
  }

  .sentiment-container {
    @include rwd-cr(768, 1280) {
      @media screen and (orientation: landscape) {
      }
      flex-direction: column;
      align-items: start;
    }

    @include mobile {
      margin-top: 12px;
      margin-bottom: 4px;
    }

    svg {
      @include rwd-cr(768, 1280) {
        @media screen and (orientation: landscape) {
        }
        width: 22px;
        height: 22px;
      }
    }

    .sentiment-label {
      @include rwd-cr(768, 1280) {
        @media screen and (orientation: landscape) {
          max-width: 100px;
        }
        max-width: 60px;
      }
    }
  }

  .suggestion {
    max-width: 300px;
    @include mobile {
      margin-top: 20px;
    }
  }
}

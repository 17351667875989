@import "../../../scss/mixins";
@import "../../../scss/variables";

.search-input-container {
  border: 1px solid #eee3d6;

  .ant-input-affix-wrapper {
    border: none;
    border-radius: 0;
  }
  .ant-input-prefix {
    margin-right: 8px;
  }
  .ant-input {
    padding-top: 0 !important;
    border: none !important;
    background: transparent !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #2e1500 !important;
  }
}

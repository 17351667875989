.chat-section {

}

.chat-card {
	border-radius: 10px;
	background: #F2F2FB;
	min-height: 300px;
	.insufficient-cefr {
		color: #2A2A41;
	}	
}
.chat-header {
	.chat-logo {
		border-radius: 5px;
		background: #FFF;
		padding: 7px 10px 7px 8px;
		span {
			color: #E97F40;
			font-size: 12px;
			font-weight: 700;
			line-height: 15px;
			text-transform: uppercase;
			letter-spacing: -0.28px;

		}
	}
	.expand-btn {
		position: relative;
		top: 6px;
		border-radius: 40px;
		background: #1C1C1C;

		padding: 10px 25px;
		color: #FFF;
		text-align: right;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 19px;
		letter-spacing: -0.48px;
	}
}
.chat-title {
	.heading {
		color: #2A2A41;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		letter-spacing: -0.5px;
	}
	.question-text,
	.summary-text {
		width: 75%;
	}
	.chat-summary-text {
		width: 82%;
	}
	.summary-text,
	.chat-summary-text  {
		opacity: 0.8;
		color: #2A2A41;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 18px;
	}
}
.cefr-score {
	p {
		font-size: 16px;
		font-weight: 700;
		line-height: 19px;
		letter-spacing: -0.5px;
		text-align: left;
	}
	.score-pill {
		width: 49px;
		height: 47px;
		padding: 10px;
		border-radius: 40px;
		gap: 10px;
		background: #000000;

		font-size: 19px;
		font-weight: 700;
		line-height: 27px;
		letter-spacing: -0.5px;
		text-align: left;
		color: #FFFFFF;
	}
}
.cefr-markers-container {
	gap: 24px;
	column-gap: 62px;
	.cefr-marker {
		gap: 6px;
		.marker {
			letter-spacing: -0.5px;
			&-score {
				font-size: 25px;
				font-weight: 700;
				line-height: 30px;
				text-align: left;
			}
			&-name {
				font-size: 16px;
				font-weight: 700;
				line-height: 17.2px;
			}
			&-desc {
				font-size: 16px;
				font-weight: 600;
				line-height: 19px;
				text-align: left;
				color: #365CE3;
			}
		}
	}
}


// Chat Detail Modal
.chat-detail-modal {
	.ant-modal-body {
		padding: unset !important;
	}
	.ant-modal-content {
		border-radius: 40px !important;
		max-width: 1440px;
	}
	.ant-modal-close {
		display: none;
	}
	.outer-container {
		.close-icon {
			position: absolute;
			right: 30px;
			top: 10px;
			font-size: 24px;
			max-height: 98%;
		}
	}

.main-data-container {
	.left-portion {
		width: 65%;
		border-right: 1px solid;
		padding: 30px 0 30px 30px;
	}
	.ltop-portion,
	.lbottom-portion {
		display: grid;
		grid-template-columns: 1fr 1fr;
		row-gap: 30px;
		column-gap: 50px;
		padding: 0 40px 40px 0;
		border-bottom: 1px solid;
	}
	.lbottom-portion {
		padding: 40px 40px 20px 0;
		border-bottom: unset;
	}
	.right-portion {
		width: 35%;
		padding: 30px 0 30px 30px;
		max-height: 640px;
	}
	.cefr-marker {
		display: flex;
		column-gap: 20px;
		.marker {
			&-score {
				position: relative;
				top: 24%;
				color: #2A2A41;
				font-size: 19px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				letter-spacing: -0.5px;
			}
			&-info {
				display: flex;
				flex-direction: column;
				row-gap: 4px;
			}
			&-name {
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				letter-spacing: -0.5px;
			}
			&-desc {
				display: flex;
				align-items: center;
				color: #A5A5A5;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				letter-spacing: -0.5px;
			}
		}
	}
}
.left-scroll-section {
	display: flex;
  flex-direction: column;
	max-height: 450px;
	overflow-y: scroll;
}
.right-scroll-section {
	display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 1.5rem;
	max-height: 470px;
	overflow-y: scroll;
}
.chat-message {
	&-wrapper {
		display: flex;
		align-items: flex-start;
		gap: 0.5rem;
		font-size: 1rem;
		font-weight: 500;
	}
	&-inner {
		width: fit-content;
		max-width: 60%;
		padding-left: 1.25rem;
		padding-right: 1.25rem;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}
}
.bot-msg {
	border-top-right-radius: 1.5rem;
	border-bottom-left-radius: 1.5rem;
	background-color: #F2F2F2;
}
.user-msg {
	border-top-left-radius: 1.5rem;
	border-bottom-right-radius: 1.5rem;
	background-color: #F9E4CB;
}
}
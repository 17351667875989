.first-report-page {
  -webkit-print-color-adjust: exact !important;
  background: rgb(204, 204, 204);
  font-family: "Inter", sans-serif;
  font-weight: 300;

  .pdf-unberry-logo {
    > svg {
      height: 35px;
      width: auto;
    }
  }

  p {
    // line-height: 22px;
    color: #7d7d7d;
    font-size: 14px;
    word-wrap: break-word;
  }

  .flex-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text-info {
    font-size: 20px;
    font-weight: 500;
  }

  .divider {
    height: 3px;
    background: #f2f2f2;
    border-radius: 60px;
    margin: 57px 0;
  }

  .radar-wrapper {
    & > div:first-child {
      flex: 1;
    }
    > .spider-chart {
      flex: 1.7;
    }
  }

  .attention-trait-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .text-grey {
    color: #7d7d7d !important;
  }
  .text-blue {
    color: #3c95eb;
  }
  .text-black {
    color: #121319;
  }
  .text-purple {
    color: #be6af6;
  }
  .bg-grey {
    background-color: #f8f8fa;
  }

  .hero-img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    float: left;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

  .gameplay-stats {
    border: 1px solid #d2d2d2;
    border-radius: 14px;
  }
  .note-bg {
    background-color: #f8f8fa;
    padding: 10px 20px;
    border-radius: 10px;
  }

  .info-section {
    margin: 111px 0 0;
  }

  .img-hero-style {
    position: relative;
    height: fit-content;
    margin-right: -50px;
  }

  .competency-traits {
    margin-top: 55px;
  }

  .traits-wrapper {
    & > .about-traits:not(:last-child) {
      &::after {
        content: "";
        border-bottom: 1px solid #e0e0e0;
        margin: 23px 0;
        width: 100%;
        display: block;
        grid-column: 1 / -1;
      }
    }
  }

  .driver,
  .derailer {
    .factors {
      gap: 34px;
    }
  }
  .about-unberry-wrapper {
    > div.about-unberry {
      width: 70%;
      margin-top: 500px;
    }
  }

  // ----------------------
  // PDF Report Overides
  // ----------------------
  // These can be removed once the styling done as per the common.scss styles
  * {
    line-height: 1.5;
  }

  // grid-cols
  @for $i from 1 through 12 {
    .grid-cols-#{$i} {
      grid-template-columns: repeat($i, minmax(0, 1fr)) !important;
    }
  }

  // col-gap
  @for $i from 1 through 30 {
    .col-gap-#{$i} {
      column-gap: 1px * $i !important;
    }
  }

  // grid-rows
  @for $i from 1 through 12 {
    .grid-rows-#{$i} {
      grid-template-rows: repeat($i, minmax(0, 1fr)) !important;
    }
  }

  // font-sizes
  @for $i from 1 through 100 {
    .size-#{$i} {
      font-size: 1px * $i !important;
    }
  }

  // text-opacity
  @for $i from 0 through 100 {
    .opacity-#{$i} {
      opacity: ($i) / 100 !important;
    }
  }

  // font-weight
  @for $i from 100 through 900 {
    .weight-#{$i} {
      font-weight: $i !important;
    }
  }

  // width
  @for $i from 1 through 200 {
    .width-#{$i} {
      width: 1px * $i !important;
    }
  }
  @for $i from 1 through 200 {
    .height-#{$i} {
      height: 1px * $i !important;
    }
  }

  // padding
  @for $i from 0 through 60 {
    .p-#{$i} {
      padding: 1px * $i !important;
    }
  }

  // padding-horizontal
  @for $i from 0 through 40 {
    .px-#{$i} {
      padding-left: 1px * $i !important;
      padding-right: 1px * $i !important;
    }
  }
  // padding-vertical
  @for $i from 0 through 40 {
    .py-#{$i} {
      padding-top: 1px * $i !important;
      padding-bottom: 1px * $i !important;
    }
  }

  // padding-vertical
  @for $i from 0 through 40 {
    .my-#{$i} {
      padding-top: 1px * $i !important;
      padding-bottom: 1px * $i !important;
    }
  }

  // padding-bottom
  @for $i from 0 through 60 {
    .pb-#{$i} {
      padding-bottom: 1px * $i !important;
    }
  }

  // padding-top
  @for $i from 0 through 60 {
    .pt-#{$i} {
      padding-top: 1px * $i !important;
    }
  }

  // padding-left
  @for $i from 0 through 60 {
    .pl-#{$i} {
      padding-left: 1px * $i !important;
    }
  }

  // padding-right
  @for $i from 0 through 60 {
    .pr-#{$i} {
      padding-right: 1px * $i !important;
    }
  }

  // margin
  @for $i from 0 through 40 {
    .m-#{$i} {
      margin: 1px * $i !important;
    }
  }

  // margin-horizontal
  @for $i from 0 through 40 {
    .mx-#{$i} {
      margin-left: 1px * $i !important;
      margin-right: 1px * $i !important;
    }
  }

  // margin-vertical
  @for $i from 0 through 40 {
    .my-#{$i} {
      margin-top: 1px * $i !important;
      margin-bottom: 1px * $i !important;
    }
  }
  // margin-top
  @for $i from 0 through 50 {
    .mt-#{$i} {
      margin-top: 1px * $i !important;
    }
  }

  // margin-bottom
  @for $i from 0 through 60 {
    .mb-#{$i} {
      margin-bottom: 1px * $i !important;
    }
  }

  //margin-left
  @for $i from 0 through 40 {
    .ml-#{$i} {
      margin-left: 1px * $i !important;
    }
  }

  // margin-right
  @for $i from 0 through 40 {
    .mr-#{$i} {
      margin-right: 1px * $i !important;
    }
  }

  // border
  @for $i from 0 through 40 {
    .br-#{$i} {
      border-radius: 1px * $i !important;
    }
  }

  // border-top-left
  @for $i from 0 through 40 {
    .btl-#{$i} {
      border-top-left-radius: 1px * $i !important;
    }
  }

  // border-top-right
  @for $i from 0 through 40 {
    .btr-#{$i} {
      border-top-right-radius: 1px * $i !important;
    }
  }

  // border-bottom-left
  @for $i from 0 through 40 {
    .bbl-#{$i} {
      border-bottom-left-radius: 1px * $i !important;
    }
  }

  // border-bottom-right
  @for $i from 0 through 40 {
    .bbr-#{$i} {
      border-bottom-right-radius: 1px * $i !important;
    }
  }

  img {
    max-width: 100%;
    display: block;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
  }

  // alignments
  .align-right {
    text-align: right !important;
  }
  .align-left {
    text-align: left !important;
  }
  .align-center {
    text-align: center !important;
  }
}

@import "../../../scss/variables";
@import "../../../scss/mixins";

.tag-select {
  .select-box {
    border-radius: 50px;
    background: #f3f3f3;
    color: var(--dark-color);
    border: 1px solid #dddddd;
    padding: 0px 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    margin-top: 8px;

    &.active {
      color: var(--bg-white);
      background: var(--primary-color);
    }
  }
  .error {
    font-size: 11px;
    color: var(--error-color);
  }

  .tag-select-items {
    flex-wrap: wrap;
  }
}

@import "../../../scss/variables";
@import "../../../scss/mixins";

.candidate-formdata-modal {
  max-height: 728px;
  @include mobile {
    max-height: unset;
  }
  .ant-modal-content {
    padding: 20px 30px;
    p {
      color: rgba(28, 28, 28, 1);
    }
    .candidate-details-content {
      min-height: 50vh;
      @include mobile {
        height: 70vh;
        overflow-y: auto;
      }
      .category {
        padding-bottom: 7px;
        border-bottom: solid thin rgba(217, 217, 217, 1);
      }
      .input-label {
        margin-bottom: 3px;
      }
      .cv-link {
        color: rgba(255, 134, 78, 1);
      }
      .slide-up {
        animation: fadeInUp 0.5s ease;
      }
      .download-icon {
        padding: 12px;
        background: var(--light-grey);
        border-radius: 50%;
      }
      .grid-cols-3 {
        @include mobile {
          grid-template-columns: repeat(1, 1fr) !important;
        }
      }
      .grid-new-col {
        grid-column-start: 1;
      }
    }
    .loading-container {
      height: 40vh;
    }
    .empty-data-container {
      height: 40vh;
      svg {
        width: 30px;
        height: 30px;
        animation: userNod 1.5s ease infinite;
        transform-origin: bottom;
      }
      .oval-shadow {
        width: 18px;
        height: 1.5px;
        background-color: #fff;
        border-radius: 50%;
        animation: shadowSlide 1.5s ease infinite;
      }
    }
  }
}

@keyframes userNod {
  0% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(-15deg);
  }
}

@keyframes shadowSlide {
  0% {
    box-shadow: -10px 10px 4px rgba(0, 0, 0, 0.7);
  }
  50% {
    box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.7);
  }
  100% {
    box-shadow: -10px 10px 4px rgba(0, 0, 0, 0.7);
  }
}

@keyframes fadeInUp {
  from {
    transform: translateY(10px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

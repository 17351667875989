@import "/src/scss/mixins";
@import "/src/scss/variables";

.general-modal {
  padding: 0;
  @include mobile {
    height: 100%;
    max-width: unset;
    width: 100% !important;
  }
  .ant-modal-content {
    background: var(--text-white);
    padding: 35px;
    border-radius: 0;
    border: 1px solid var(--primary-color);
    color: var(--dark-color);
    @include mobile {
      bottom: 0px;
      top: unset;
      left: 0;
      position: absolute;
      margin: 0;
      width: 100%;
      border-radius: 0 !important;
    }
  }
  .ant-modal-body {
    padding: 0 !important;
    margin: 0 !important;
  }
  .ant-modal-header {
    margin: 0;
    padding: 0 0 20px 0;
  }
}

@import "../../scss/variables";
@import "../../scss/mixins";

.position-container {
  position: relative;
  z-index: 1;
  background: var(--main-container-bg);
  width: 100%;

  .blank-message {
    margin-top: 6%;
  }

  .tab-wrapper {
    margin-top: 25px;
  }

  // .container {
  //   @include desktop-lg {
  //     padding: 0 !important;
  //     max-width: 1440px;
  //     width: calc(100vw - 140px);
  //     margin: auto !important;
  //   }
  // }

  .gameplayStatus {
    &.playing {
      color: var(--dark-color);
    }
    &.completed {
      color: lightgreen;
    }
  }

  .content-wrapper {
    max-width: 1440px;
    padding: 0 70px;
    margin: auto;
    height: calc(100vh - 95px);
    overflow: auto;

    @include mobile {
      height: calc(100vh - 72px);
      padding: 0 0 90px;
    }
  }
  // .heading-block {
  //   padding: 40px 0 0 0;
  //   border-bottom: 1px solid #ebd8c1;

  //   @include mobile {
  //     flex-direction: column-reverse;
  //   }

  //   .gameplay-count {
  //     color: #dcbc97;
  //     vertical-align: text-top;
  //   }

  //   .btn-box {
  //     @include mobile {
  //       width: 100%;
  //       position: fixed;
  //       bottom: 0;
  //       z-index: 1;
  //       padding: 20px 12px;
  //       background: var(--main-container-bg);
  //     }

  //     .ant-btn {
  //       height: 50px;
  //       padding: 0 20px !important;
  //       @include mobile {
  //         width: 100%;
  //       }

  //       svg {
  //         vertical-align: baseline;
  //         margin-right: 8px;
  //       }
  //     }
  //   }
  // }

  .card-container {
    margin-top: 30px;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    @include mobile {
      justify-content: center;
      grid-template-columns: repeat(1, 1fr);
      margin: 0;
      padding: 18px;
    }

    @include rwd-cr(968, 1080) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include rwd-cr(768, 968) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .blank-listing-img {
    height: 200px;
  }
  .position-type {
    border-radius: 15px;
  }
  .action-btn {
    border-radius: 15px;
    background: var(--dark-color);
  }

  .item-box {
    grid-column-gap: 40px;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    @include mobile {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .accordion-box {
    padding: 10px 0px;
    border-top: 1px solid rgba(#c4c4c4, 0.1);
    border-bottom: 1px solid rgba(#c4c4c4, 0.1);

    .ant-collapse {
      border: 0;
      border-radius: 0;
      background: transparent;

      .ant-collapse-item {
        background: transparent;
      }

      .ant-collapse-header {
        padding: 10px 0 !important;

        .anticon {
          width: 32px;
          height: 32px;
          border-radius: 100px;
          background: var(--dark-color);
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            path {
              fill: var(--text-white);
            }
          }
        }
        .light {
          span {
            font-size: 20px !important;
            font-weight: 600;
            line-height: 28px;
            margin-right: 66px;
          }
        }
      }

      .ant-collapse-content.ant-collapse-content-active {
        padding: 10px 0px;
      }
    }
  }

  .empty-state-cont {
    height: calc(100vh - 72px);
  }
}

// ------------ add new position form scss ------------
.position-form {
  .content-wrapper {
    @include tablet {
      max-width: 100%;
    }
    @include mobile {
      max-width: 100%;
    }
  }

  .comingsoon-sec {
    background: var(--dark-color);
    border: 1px solid var(--secondary-color);
    border-radius: 16px;
    padding: 20px;

    .shape-green {
      right: 50px;
      top: 0px;
    }
    .shape-purple {
      right: 100px;
      bottom: 0;
    }
    .shape-orange {
      right: -30px;
      bottom: 10px;

      @include mobile {
        right: 0;
      }
    }

    .titleNameStyle {
      color: #d8d8d8 !important;
    }

    .tag-block {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .tag-box {
        margin: 10px 10px 0 0;
      }
    }
  }
}

// ------------ position details scss ------------
.position-detail-conatiner {
  .position-detail-sec {
    display: grid;
    grid-template-columns: 0.75fr 2fr;
    grid-column-gap: 30px;
    padding: 10px 0px 30px;

    @include tablet {
      display: block;
    }
    @include mobile {
      display: block;
    }

    .left-portion {
      @include tablet {
        margin-bottom: 30px;
      }
      @include mobile {
        margin-bottom: 30px;
      }
    }

    .top-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include tablet {
        // flex-direction: column;
        align-items: flex-start;
      }
      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      }

      .first-half,
      .second-half {
        flex: 1;
        width: 100%;
      }
      .second-half {
        text-align: right;

        @include mobile {
          width: 100%;
          position: fixed;
          left: 0;
          bottom: 0;
          z-index: 1;
          padding: 20px 12px;
          background: var(--dark-color);
        }

        .ant-btn {
          @include mobile {
            width: 100%;
          }
        }
      }
    }

    .filter-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include tablet {
        flex-direction: column;
        align-items: flex-start;
      }
      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      }

      .second-half {
        flex: 1;
        width: 100%;
        margin-left: 20px;

        @include tablet {
          margin: 20px 0 0 0;
        }
        @include mobile {
          margin: 20px 0 0 0;
        }

        .item-box {
          grid-column-gap: 20px;

          .item {
            & + .item {
              @include mobile {
                margin-top: 20px;
              }
            }
          }
        }

        .item-label {
          @include mobile {
            min-width: 80px;
          }
        }

        .field-container {
          width: 100%;
        }
      }
    }
  }
}
.container {
    padding-top: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    padding: 0 5vw;
    height: 100%;
    overflow: auto;
    margin: 0 auto;
    max-width: 1440px;
}

.skillsSideMenu {
    overflow: auto;
    position: sticky;
    top: 28px;
}
